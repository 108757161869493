<template>
    <div v-show="showModal" class="c-Main-modal c-Promotions">
        <div class="c-Main-modal__wrapper">
            <div class="c-Main-modal__container" :class="{'c-Promotions__single' : modal.length === 1}">
                <div class="c-Main-modal__header" v-if="modal.length && modal.length > 1">
                    <h3>
                        <svg viewBox="0 0 20 20" class="o-Icon__svg o-Icon__svg--20 o-Icon__svg--purple">
                            <use xlink:href="/assets/build/svg/defs/svg/sprite.defs.svg#info_exclamation_icon"
                                class="o-Icon__svg__inner"></use>
                        </svg>
                        {{modal[0].title}}
                    </h3>
                    <a href="javascript:void(0)" @click.prevent="closeModal();" class="c-Main-model-header__close o-Icon-bullet o-Icon-bullet--close-blue"></a>
                </div>

                <!-- Single Promo -->
                <div class="c-Main-modal__body" v-if="modal.length == 1">
                    <figure v-if="imageUrl" class="c-Promotions__full-bleed" :style="{backgroundImage: 'url(' + imageUrl + ')'}">                        
                        <a href="javascript:void(0)" @click.prevent="closeModal();" class="c-Main-model-header__close o-Icon-bullet o-Icon-bullet--close-blue"></a>
                    </figure>
                    <div class="c-Promotions__body">
                        <p class="c-Promotions__single-content">
                            {{modal[0].content}}
                        </p>
                        <a class="o-Btn o-Btn--purple" :href="modal[0].button.link" @click.prevent="openLink(modal[0].button.link)" v-html="modal[0].button.label"></a>
                    </div>
                </div>

                <!-- Multi Promo -->
                <div class="c-Main-modal__body c-Promotions__multi" v-if="modal.length > 1">
                    <div class="c-Promotions__multi-item" v-for="promo in modal" :key="promo.id">
                        <figure @click="openLink(promo.button.link)" class="c-Promotions__full-bleed" :style="{backgroundImage: 'url(' + promo.imageUrl + ')'}">
                        </figure>
                        <div class="c-Promotions__body">
                            <p class="c-Promotions__single-content">
                                {{promo.content}}
                            </p>
                            <a class="o-Btn o-Btn--purple" :href="promo.button.link" @click.prevent="openLink(promo.button.link)" v-html="promo.button.label"></a>
                        </div>
                    </div>
                </div>

                <div class="c-Main-modal__footer">
                    <a href="javascript:void(0)" class="c-Promotions__footer-link" @click="donotshow">{{immiLang.dontShowThis}}</a>
                    <a :href="immiLang.toolsLink" class="c-Promotions__footer-link">{{immiLang.seeAllTools}}</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import VueResource from "vue-resource";
Vue.use(VueResource);
export default {
    name: 'promotion',
    data() {
        return {
            showModal: false,
            modal: [],
            imageUrl: '',
            immiLang: window.immiLang?.promotions || {},
            currentPromos: [],
            localStorePromos: []
        };
    },
    created: function () {
        try {
            let isSet = window.localStorage.getItem('immiTools');
            if (isSet) {
                isSet = JSON.parse(isSet) || [];
                this.localStorePromos = isSet || [];
            }
            this.getPromotionData();
        } catch (error) {
            console.log('Please enable cache or session storage to serve you better.');
            this.localStorePromos = [];
            this.getPromotionData();
        }
    },
    methods: {
        getPromotionData () {
            this.$http.get('/wp-json/wp/v2/promotions').then(res => {
                const responseBody = res.body && Array.isArray(res.body) ? res.body : [];
                if (responseBody && responseBody.length) {
                    let modal = {};
                    const currentLang = window.immiObj.currentLanguage || 'English';
                    responseBody.forEach(element => {
                        modal = {};
                        const metaData = element.status === 'publish' ? element.promotion_meta : {};
                        modal.language = this.extractAllText(metaData.promotions.promotion_language) || ['English'];
                        if (metaData && metaData.promotions && typeof metaData.promotions == "object") {
                            if (modal.language.indexOf(currentLang) > -1 && currentLang === 'Chinese' && Array.isArray(metaData.promotions.promotion_title_zh) && metaData.promotions.promotion_title_zh.length) {
                                modal.title = metaData.promotions.promotion_title_zh ? metaData.promotions.promotion_title_zh[0] : '';    
                                modal.content = metaData.promotions.promotion_content_zh ? metaData.promotions.promotion_content_zh[0] : '';
                                modal.button = {
                                    label: metaData.promotions.promotion_button_label_zh ? metaData.promotions.promotion_button_label_zh[0] : '',
                                    link: metaData.promotions.promotion_button_link_zh ? metaData.promotions.promotion_button_link_zh[0] : ''
                                };
                            } else if (modal.language.indexOf(currentLang) > -1 && currentLang === 'Spanish' && Array.isArray(metaData.promotions.promotion_title_es) && metaData.promotions.promotion_title_es.length) {
                                modal.title = metaData.promotions.promotion_title_es ? metaData.promotions.promotion_title_es[0] : '';    
                                modal.content = metaData.promotions.promotion_content_es ? metaData.promotions.promotion_content_es[0] : '';
                                modal.button = {
                                    label: metaData.promotions.promotion_button_label_es ? metaData.promotions.promotion_button_label_es[0] : '',
                                    link: metaData.promotions.promotion_button_link_es ? metaData.promotions.promotion_button_link_es[0] : ''
                                };
                            } else if (metaData.promotions.promotion_title && Array.isArray(metaData.promotions.promotion_title) && metaData.promotions.promotion_title.length) {
                                modal.title = metaData.promotions.promotion_title[0] || '';
                                modal.content = metaData.promotions.promotion_content[0] || '';
                                modal.button = {
                                    label: metaData.promotions.promotion_button_label[0] || '',
                                    link: metaData.promotions.promotion_button_link[0] || ''
                                };
                            }
                        }
                        modal.imageUrl = metaData.imageUrl;
                        this.currentPromos.push({
                            id: element.id,
                            modified: element.modified,
                            language: modal.language
                        });
                        this.localStorePromos.forEach(local=>{
                            let diffLang = false;
                            let isLangDiff = local.language.forEach(lang => {
                                if (modal.language.indexOf(lang) === -1) {
                                    diffLang = true;
                                } else {
                                    modal.language.splice(modal.language.indexOf(lang), 1);
                                }
                            });
                            if (local.id === element.id && local.modified !== element.modified && (diffLang || modal.language.length == 0) && (modal.language.length == 0 || modal.language.indexOf(currentLang) > -1)) {
                                this.modal.push(modal);
                            }
                        });
                        if(!this.localStorePromos.length && modal.language.indexOf(currentLang) > -1 && modal.title) {
                            this.modal.push(modal);
                        }
                    });
                    
                    if(this.modal.length === 1) this.imageUrl = this.modal[0].imageUrl;
                    
                    if (this.modal.length) {
                        this.openModal();
                    }
                }
            }, error =>{

            })
        },
        closeModal () {
            this.showModal = false;
            document.querySelector('body').classList.remove('is-Locked');
        },
        openModal () {
            this.showModal = true;
            document.querySelector('body').classList.add('is-Locked');
        },
        donotshow () {
            try {
                window.localStorage.setItem('immiTools', JSON.stringify(this.currentPromos));
                this.closeModal();
            } catch (error) {
                this.closeModal();
                console.log('Please enable cache or session storage to serve you better.');
            }
        },
        openLink (link) {
            this.donotshow();
            this.$nextTick(()=>{                
                window.open(link, '_blank');
            });
        },
        extractAllText (str){
            const re = /"(.*?)"/g;
            const result = [];
            let current;
            while (current = re.exec(str)) {
                result.push(current.pop());
            }
            return result.length > 0
                ? result
                : [str];
            }    
        }
}
</script>