import { breakpoints, globalColors } from '../globals/config';
import { getEl } from '../helpers/helpers';

(function() {
    const link1 = getEl('footer_link1');
    const link2 = getEl('footer_link2');
    const link3 = getEl('footer_link3');
    const link1Body = getEl('footer_link1Body');
    const link2Body = getEl('footer_link2Body');
    const link3Body = getEl('footer_link3Body');
    let toggleLink1 = false;
    let toggleLink2 = false;
    let toggleLink3 = false;
    const notLarge = () => window.innerWidth < breakpoints.medium;

    link1.addEventListener('click', () => {
        if( notLarge() ) {
            unsetToggleLinks(toggleLink2,toggleLink3,link2Body,link3Body,link2,link3);
            toggleLink1 = !toggleLink1;
            setDropdownClass(toggleLink1,link1Body,link1);
        }
    });

    link2.addEventListener('click', () => {
        if( notLarge() ) {
            unsetToggleLinks(toggleLink1,toggleLink3,link1Body,link3Body,link1,link3);
            toggleLink2 = !toggleLink2;
            setDropdownClass(toggleLink2,link2Body,link2);
        }
    });

    link3.addEventListener('click', () => {
        if( notLarge() ) {
            unsetToggleLinks(toggleLink1,toggleLink2,link1Body,link2Body,link1,link2);
            toggleLink3 = !toggleLink3;
            setDropdownClass(toggleLink3,link3Body,link3);
        }
    });


    function setDropdownClass(link,linkBody,linkParent) {
        link === true ? addClass(linkBody,linkParent): removeClass(linkBody,linkParent);
    }

    function addClass(arg1,arg2) {
        arg1.classList.add('c-Footer__more-links-block');
        arg1.classList.add('c-Footer__block--display');
        arg2.classList.add('c-Footer__link-item__rotate-arrow');
    }

    function removeClass(arg1,arg2) {
        arg1.classList.remove('c-Footer__more-links-block');
        arg1.classList.remove('c-Footer__block--display');
        arg2.classList.remove('c-Footer__link-item__rotate-arrow');
    }

    function unsetToggleLinks(link1,link2,link1Body,link2Body,linkParent1,linkParent2){
        link1 = link2 = false;
        removeClass(link1Body,linkParent1);
        removeClass(link2Body,linkParent2);
    }

    // Cookie policy
    checkIfCookieExists();
    
    function checkIfCookieExists () {
        const url = "/api/cookie-policy-accepted/";
		
		try {
            var xhr = new XMLHttpRequest();
            xhr.open("GET", url);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.setRequestHeader('Accept', 'application/json');
            xhr.send();
            xhr.onreadystatechange = function() {
                if (xhr && xhr.responseText) {
                    try {
                        var responseBody = JSON.parse(xhr.responseText);
                        const isCookiePresent = responseBody.cookieDTOList.find(itm=>itm.cookieName=='cookiePolicyAccepted');
                        if (!isCookiePresent && window.self == window.top) {
                            var cookiePopinElm = document.querySelector(".cookie-popin");
                            if (cookiePopinElm) cookiePopinElm.classList.remove("hide");
                            var clsBtn = document.querySelectorAll(".cookie-popin .close-button");
                            if (clsBtn.length) {
                                for (var i = 0; i < clsBtn.length; i++) {
                                    clsBtn[i].addEventListener("click", addCookiePolicy);
                                }
                            }
                        }
                    } catch (error) {
                        console.log(error);
                    }
                }
            };
        } catch (error) {
            console.error(error);
        }
    }

    let expDate = 31536000; // in seconds for 1 year
    function addCookiePolicy() {
        const url = "/api/cookie-policy-accepted/";
		const dataObj = {
			"cookieName" : "cookiePolicyAccepted",
            "cookieValue" : "true",
            "expiresOn" : expDate
		};
		
		var xhr = new XMLHttpRequest();
		xhr.open("POST", url);
		xhr.setRequestHeader('Content-Type', 'application/json');
		xhr.setRequestHeader('Accept', 'application/json');
		xhr.send(JSON.stringify(dataObj));
		xhr.onreadystatechange = function() {
            var cookiePopinElm = document.querySelector(".cookie-popin");
            if (cookiePopinElm) cookiePopinElm.classList.add('hide');
		}
    }

    // UTM parameters
    let $allLinks = document.querySelectorAll("a");
    if ($allLinks && $allLinks.length) {
        $allLinks = Array.from($allLinks);
        const currentLang = window.immiObj.currentLanguage === 'English' ? '' : window.immiObj.currentLanguage === 'Spanish' ? 'es' : 'zh';
        let campaign = immiObj.currentCat;
        const medium = document.querySelector(".post") ? 'article' : 'section';
        if (campaign) {
            campaign = campaign.split(' ');
            campaign = campaign[campaign.length - 1].toLowerCase();
            if (currentLang != '') campaign = campaign + '-' + currentLang;
        }
        $allLinks.forEach(linkElm => {
            const thisLink = linkElm.href;
            if (thisLink && thisLink.indexOf("insubuy.com") !== -1 && thisLink.indexOf("?utm_source") === -1 && 
                thisLink.indexOf("?") === -1) {
                let newLink = `${thisLink}?utm_source=immihelp&utm_medium=${medium}&utm_campaign=${campaign}`;
                linkElm.href = newLink;
            }
        })
    }

})();
