<template>
    <div>
        <modal v-cloak v-if="showModal" @close="handleClose()" 
        :id="'ui-btn-close'" :btn-type="'true'" :show-close="showClose" class="c-User-interest">

            <div slot="header">
                <template v-if="currentStep == 1">
                    <!-- STEP 1: HEADER -->
                    <h3 class="c-Main-modal-header__title topics_icon" v-html="label.topicsPage.title"></h3>
                    <p class="c-Main-modal-header__desc" v-html="label.topicsPage.description"></p>
                </template>

                <template v-if="currentStep == 2">
                    <!-- STEP 2: HEADER -->
                    <h3 class="c-Main-modal-header__title interests_icon" v-html="label.interestsPage.title"></h3>
                    <p class="c-Main-modal-header__desc" v-html="label.interestsPage.description"></p>
                </template>
            </div>

            <div class="c-Main-modal-body__wrapper" slot="body">
            
                <!-- STEP 1: CONTENT -->
                <div v-show="currentStep == 1" class="c-User-interest__body">
                    <div class="c-User-interest__list">
                        <div class="c-User-interest__list-item" v-for="(topic, inx) in topics.options" :key="inx">
                             <div class="o-Checkbox__wrapper" :class="topics.selected.indexOf(topic.id) > -1 ? 'selected' : ''">
                                <label class="o-Form__fields-label o-Form__fields-label--checkbox">
                                    <input type="checkbox" v-model="topics.selected" :name="topics.icon" :value="topic.id" :id="'ui-topic-' + topic.id">
                                    <div :class="'c-User-interest-item__icon ' + topic.icon"></div>
                                    <div class="c-User-interest-item__label">
                                        <h4>{{topic.text}}</h4>
                                        <p>{{topic.description}}</p>
                                    </div>
                                    <span class="checkmark c-User-interest__chk c-User-intereset__chk--topic"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- STEP 2: CONTENT -->
                <div v-show="currentStep == 2" class="c-User-interest__body">
                    <div class="c-User-interest__list">
                        <div class="c-User-interest__list-item" v-for="(interest, inx) in interests.options" :key="inx">
                             <div class="o-Checkbox__wrapper" :class="interests.selected.indexOf(interest.id) > -1 ? 'selected' : ''">
                                <label class="o-Form__fields-label o-Form__fields-label--checkbox">
                                    <input type="checkbox" v-model="interests.selected" :name="interest.icon" :value="interest.id" :id="'ui-interest-' + interest.id">
                                    <div :class="'c-User-interest-item__icon ' + interest.icon"></div>
                                    <div class="c-User-interest-item__label">
                                        <h4>{{interest.text}}</h4>
                                        <p>{{interest.description}}</p>
                                    </div>
                                    <span class="checkmark c-User-interest__chk c-User-intereset__chk--interest"></span>
                                </label>
                            </div>
                           
                        </div>
                    </div>
                </div>

            </div>
            <template slot="footer">
                <div v-if="isManualOpen" class="c-User-interests-btn-group">
                    <button id="ui-btn-unsub" class="o-Btn o-Btn--dark-red c-User-interest-btn c-User-interest-btn--red" @click="handleUnsubscribe()">
                        {{label.buttons.unsubscribe}}
                    </button>
                </div>
                <div class="c-User-interests-btn-group">
                    <button v-show="currentStep == 2" id="ui-btn-prev" class="o-Btn o-Btn--dark-red c-User-interest-btn" @click="goBack()">
                        {{label.buttons.previous}}
                    </button>
                    <button :id="[currentStep == 2 ? 'ui-btn-finish' : 'ui-btn-next']" class="o-Btn o-Btn--dark-red c-User-interest-btn" 
                        :class="[currentStep == 2 ? 'c-User-interest-btn--purple' : '']" 
                        @click="submitNextStep()" :disabled=disableBtn>
                        {{ currentStep == 1 ? label.buttons.next : label.buttons.finish }}
                    </button>
                </div>
            </template>
            
        </modal>
        <div style="position:relative; z-index: 999999">
            <snackbar ref="snackbar" :message="snackbarMessage" :type="messageType" />
        </div>
    </div>
</template>

<script>
import Checkbox from './components.checkbox.vue';
import Modal from './components.model.vue';
import Snackbar from "./components.snack-bar.vue";
import { 
    ApiUrls,
    RedirectsUrls,
    getItemSessionStorage,
    setItemSessionStorage
 } from "../helper-functions";

export default {
    name: 'userInterest',
    components: {
        Snackbar,
        Modal,
        'v-checkbox': Checkbox
    },
    data () {
        return {
            showModal: false,
            formSending: false,
            currentStep: 1,
            isManualOpen: false,
            minTopicsReq: 1,
            minInterestsReq: 1,
            userInterestId: '',
            currentPage: window.immiObj?.currentpage,
            label: window.immiLang.globalLabels.userInterest ? window.immiLang.globalLabels.userInterest : '',
            snackbarMessage: '',
            messageType: 'error',
            topics: {
                options: [],
                selected: [],
            },
            interests: {
                options: [],
                selected: [],
            },
            showClose: false
        }
    },
    created: function () {
        setTimeout(() => {
            this.showClose = true;
            this.$forceUpdate();
        }, 5000);
    },
    mounted: function () {
        let self = this;
        let error = getItemSessionStorage('userInterestError') || '';
        const sessionUserInterest = getItemSessionStorage('user-interest-modal') || false;
        window.userInterest = { // To open modal from Subscriptions page.
            open: function () {
                self.isManualOpen = true;
                self.handleOpen();
            }
        }
       
        setTimeout(()=> { // Wait for auth api to update
            if (!error && window.immiObj?.ath?.isLoggedIn && !window.immiObj?.ath?.selectedInterests && !sessionUserInterest) {
                this.handleOpen();
            }
        },1000)
    },
    watch: {
        showModal() { // Start on step 1 everytime
            this.currentStep = 1;
        }
    },
    computed: {
        minTopicsSelected() {
             return this.topics.selected.length >= this.minTopicsReq;
        },
        minInterestsSelected() {
             return this.interests.selected.length >= this.minInterestsReq;
        },
        disableBtn() {
            return this.currentStep == 1 ? !this.minTopicsSelected : !this.minInterestsSelected;
        }
    },
    methods: {
        // Init data from BE to display
        initUserInterestData() { 
            const result = new Promise((resolve, reject) => {
                this.$http.get(ApiUrls.userInterest).then(res => {
                    const responseBody = res.body;
                    if (responseBody?.success && responseBody.topics && responseBody.topics.length && responseBody.interests && responseBody.interests.length && (!responseBody?.userInterests?.deleted || window.immiObj.currentpage == 'subscriptions')) {
                        this.topics.options = responseBody.topics;
                        this.interests.options = responseBody.interests.filter(sub => sub.id !== 11);
                        this.setSelectedOptions(responseBody.userInterests);
                        resolve('success');
                    }else {
                        reject();
                    }
                }, error =>{
                    console.log(error)
                    reject();
                })
            })
            return result;
        },
        // Sets selected if user selected options exists
        setSelectedOptions(userInterests) {
            if (!userInterests) return;
            let topics = userInterests.topics;
            let interests = userInterests.interests;
            let selectedTopics = topics  && topics.length ?  topics.map((element)=> {
                return element.topicId;
            }) : []
            let selectedInterests = interests  && interests.length ?  interests.map((element)=> {
                return element.interestId;
            }) : []
            this.userInterestId = userInterests.id;
            this.topics.selected = selectedTopics;
            this.interests.selected = selectedInterests;
        },
        setPayload() {
            let newTopics = this.topics.selected.map( (element)=> {
                return { topicId: element };
            })
            let newInterests = this.interests.selected.map( (element)=> {
                return { interestId: element };
            })
            let payload = {
                topics: newTopics,
                interests: newInterests,
                id: this.userInterestId
            }
            return payload;
        },
        submitNextStep () {
            if (this.formSending) return;
            // Step 1 Next
            if(this.currentStep == 1) {
                if(!this.minTopicsSelected) 
                    this.showSnackbarMsg(window.immiLang.errorMessage.atLeastTwo, 'error');
                else {
                    this.currentStep = 2;
                }
            }
            // Step 2 Finish
            else if(this.currentStep == 2) {
                if(!this.minInterestsSelected) 
                    this.showSnackbarMsg(window.immiLang.errorMessage.atLeastTwo, 'error')
                else {
                    let formData = this.setPayload();
                    this.formSending = true;

                    this.$http.post(ApiUrls.userInterest, formData).then(res => {
                        const responseBody = res.body;
                        if (responseBody?.success && responseBody.loggedOut) window.location.href = RedirectsUrls.login;
                        else if(responseBody?.success) {
                            this.showSnackbarMsg(this.label.success, 'success');
                            if (this.currentPage === 'subscriptions') window.location.href = window.location.href;
                        }
                        else {
                            setItemSessionStorage('userInterestError', 'true');
                            this.showSnackbarMsg(window.immiLang.serverError, 'error');
                        }
                    }, err => {
                        console.log(err)
                        let error = err?.body?.message ? err?.body?.message : window.immiLang.serverError;
                        setItemSessionStorage('userInterestError', 'true');
                        this.showSnackbarMsg(error, 'error');
                    })
                    this.showModal = false;
                    setTimeout(()=> {
                        this.formSending = false;
                    },500)
                }
            }
        },
        goBack() {
            this.currentStep = 1;
        },
        // When opened manually
        handleOpen() {
            this.initUserInterestData()
                .then(res => {
                    if(res) this.showModal = true;
                })
        },
        // Send unsubscribe request
        handleUnsubscribe() {
            if (this.formSending) return;
            let url = ApiUrls.userInterest + '?username=' + window.immiObj.user;
            this.formSending = true;
            
            this.$http.delete(url).then(res => {
                const responseBody = res.body;
                if (responseBody?.success && responseBody.loggedOut) window.location.href = RedirectsUrls.login;
                else if (responseBody?.success) {
                    this.showSnackbarMsg(this.label.unsubscribed, 'success');
                    window.location.href = window.location.href;
                }
                else {
                    this.showSnackbarMsg(window.immiLang.serverError, 'error');
                }
            }, error =>{
                console.log(error)
                this.showSnackbarMsg(window.immiLang.serverError, 'error');
            })
            this.showModal = false;
        },
        handleClose() {
            this.showModal = false;
            if (!this.isManualOpen) {
                setItemSessionStorage('user-interest-modal', 'true');
            }
        },
        showSnackbarMsg (msg, type) {
            this.snackbarMessage = msg;
            this.messageType = type;
            this.$refs.snackbar.open();
        },
    }
}
</script>
