import polyfills from '../../wordpress/modules/polyfills/polyfills';
import header from '../../wordpress/components/header';
import revenuegenerator from '../../wordpress/components/revenue-generator.js';
import footer from '../../wordpress/components/footer';
import clickAway from '../../wordpress/modules/clickAway/clickAway';
import svg4everybody from '../../wordpress/modules/svg4everybody/svg4everybody.min';

import Vue from 'vue';
import Promotion from '../../wordpress/components/promotion-modal.vue';
import UserInterest from '../../components/components.user-interest.vue';
import InlineAds from '../../components/components.inline-ads.vue';
require('../../section-specific/blog/blog').default;

new Vue({
    el: '#site-wide',
    components: {
        Promotion,
        UserInterest
    }
})

new Vue({
    el: '#main',
    components: {
        InlineAds
    },
    data: {
        name: "Main"
    },
    created () {
        const $blogLanding = document.querySelector(".c-Blog__featured");
        if ($blogLanding) {
            this.getViewsByPost();
        }
    },
    methods: {
        getViewsByPost () {
            let allPosts = document.querySelectorAll(".view-count");
            allPosts = Array.from(allPosts);
            if (Array.isArray(allPosts)) {
                let formData = new FormData();
                formData.append('action', 'immi_get_view_api');
                allPosts.forEach((elm, inx) => {
                    formData.append('postID['+inx+']', elm.getAttribute("data-post-id"));
                });
                this.$http.post(window.immiObj.viewPostLink, formData).then(res => {
                    const responseBody = res.body;
                    if(responseBody) {
                        const allViews = responseBody.split(',,,,');
                        allPosts.forEach((elm, inx) => {
                            if (elm) elm.innerText = parseInt(allViews[inx]).toLocaleString();
                        })
                    }
                })
            }
        }
    }
})

svg4everybody();