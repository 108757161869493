<template>
  <div>
    <div class="c-Experience-cat__replies">
      <nested-reply :node="replies" :is-blog="isblog" :is-sponsors="isSponsors" :post-id="postid"></nested-reply>
    </div>
  </div>
</template>

<script>
import NestedReply from "./components.nested-reply.vue";

export default {
    name: 'ChainTree',
    components: {
        NestedReply
    },
    props: {
        replies: Object,
        postid: Number,
        isblog: Boolean,
        isSponsors: {
          type: Boolean,
          default: false
        }
    },
};
</script>