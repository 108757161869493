<template>
    <div :id="'reply-' + id" v-if="showReply" class="c-Experience-cat__reply-submittion hide-when-print" :class="{'c-Experience__reply--nested': isNested=='true'}">        
        <div class="c-Experience-cat__comment">{{immiLang.replyTerms.comment}}</div>
        <div>
            <textarea rows="10" class="c-Experience-cat__comment-area" v-model.trim="replyPost"></textarea>
        </div>
        <div class="c-Experience-cat__card-division c-Experience-cat__margin-top">
            <div class="cardHeading">
                <div class="o-Form__error" v-if="showLoginError">
                    <p v-for='error in replyErrorMessage' :key='error' v-html='error'></p>
                </div>
                <div class="o-Form__terms" v-html="termsOfuse"></div>
            </div>
            <div class="o-Form__btn o-Form__btn--right">
                <button class="o-Btn o-Btn--dark-red o-Btn--bold-upper" @click.prevent="closeThisReply">{{immiLang.replyTerms.cancel}}</button>
                <button class="o-Btn o-Btn--blue-drk u-Position-relative o-Btn--bold-upper" type="button" :disabled="replyPost.length==0 || isLoading || pauseSubmit" v-on:click="addReply()">
                    <span v-show="!isLoading">{{immiLang.replyTerms.post}}</span>
                    <span v-show="isLoading" ref="btnspinner" class="o-Btn-spinner"></span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import {
    Spinner
} from 'spin.js';
import { ApiUrls } from "../helper-functions/api-urls";
import {spinOpts, censorshipStr} from "../helper-functions/helper-functions";
export default {
    name: 'addreply',
    props: {
        id: Number, //post id
        parentId: Number, // parent comment id if present
        showReply: Boolean,
        isNested: String,
        isBlog: Boolean,
        isSponsors: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            replyPost: "",
            isLoading: false,
            showLoginError: false,
            replyErrorMessage: [],
            termsOfuse: window.immiLang.termsOfuse,
            immiLang: window.immiLang || {},
            pauseSubmit: window.immiObj.pauseAction
        }
    },
    methods: {
        addReply () {
            const censorReply = censorshipStr(this.replyPost);
            let data = {
                comment: censorReply,
                id: this.id,
                parent: this.parentId ? true : false
            };

            if(data.parent) {
                data.parentId = this.parentId;
            }

            this.isLoading = true;
            const spinTarget = this.$refs.btnspinner;
            var spinner = new Spinner(spinOpts).spin(spinTarget);
            let url = ApiUrls.experience.addReply;
            if(this.isBlog) {
                url = ApiUrls.blog.addBlogComment;
                data.blogId= this.id;                
                data.basicParentId = this.parentId ? this.parentId : -1;
                data.parentId = this.parentId ? this.parentId : -1;
                data.blogName = document.querySelector('.c-Article-main-title').innerText.trim();
                data.blogLink = window.location.pathname;
                data.status = "Active";
                delete data.id;
            } else if (this.isSponsors) {
                url = ApiUrls.sponsors.addReply;
            }
            if (window.immiObj.currentLangCode == 'zh') {
                data.lang = 'zh';
            }
            else if (window.immiObj.currentLangCode == 'es') {
                data.lang = 'es';
            }
            this.$http.post(url, data).then(response => {
                this.isLoading = false;
                const responseBody = response.body;
                if(responseBody.message == "success") {
                    this.$root.$emit('refreshReplies', this.id);
                    this.replyPost = "";
                    this.closeThisReply();
                } else {
                    if(responseBody.message == "error") {
                        if(Array.isArray(responseBody.error) && responseBody.error.length) {
                            this.showLoginError = true;
                            this.replyErrorMessage = responseBody.error;
                        }
                    }
                }
            }, error => {
                this.isLoading = false;
                console.log(error);
            })
        },
        closeThisReply() {
            this.replyPost = "";
            this.$root.$emit('closeNestedReplies', this.id, true);
            this.$emit('close', this.id);
        }
    }
}
</script>

<style lang="scss" scoped>
.c-Experience-cat__reply-submittion {
    background-color: #f4f4f4;
    padding: 1.45em;
    border-top: 1px solid #dadada;    
}

.c-Experience__reply--nested {
    border: 1px solid #dadada;
    margin-top: 1.5em;
}
</style>