import Vue from 'vue';
import AddReply from '../../components/components.add-reply.vue';
import VueResource from 'vue-resource';

import {
    isUserLoggedIn,
    RedirectsUrls,
    setItemSessionStorage,
    getItemSessionStorage,
    deleteSessionStore,
    SessionKeys
} from '../../helper-functions';

import ChainTree from "../../components/components.parent-reply.vue";
import Snackbar from "../../components/components.snack-bar.vue";
import ButtonSvg from "../../components/components.button-svg.vue";
import Spinner from '../../components/components.spinner.vue';

Vue.use(VueResource);

const credMainElm = document.getElementById('cred-main');

Vue.component('blog-comments', { 
    name: 'blog-comments',
    components: {
        Snackbar,
        ChainTree,
        AddReply,
        ButtonSvg,
        Spinner
    },
    data () {
        return {
            name: 'Blog',
            replies: [],
            blogId: null,
            itsBlog: true,
            toggleReply: false,
            isFetching: false,
            snackbarMessage: '',
            snackbarType: '',
            allReplies: 0
        }
    },
    created () {
        const thisBlogElm = document.querySelector("#content [id*='post-']");
        if(thisBlogElm) {
            this.blogId = +thisBlogElm.id.replace('post-', '');
        }
        this.getAllReplies();
        this.checkIfUserLoggedIn();

        this.$root.$on('closeMainReply', () => {
            this.closeThisReply();
        });

        this.$root.$on('refreshReplies', (id) => {
            this.getAllReplies();
        });

        /**
         * See if session has the variable to open add Exp block.
         * If the session key is available then open and delete the key.
         */
        if (isUserLoggedIn && isUserLoggedIn.isLoggedIn) {
            const isAddExpOpen = getItemSessionStorage(SessionKeys.blog.openAddBlog);
            if (isAddExpOpen) {                
                deleteSessionStore(SessionKeys.blog.openAddBlog);
            }
        }
    },
    mounted () {
        this.removeExtraBorders();
    },
    updated: function () {
        this.$nextTick(() => {
            this.removeExtraBorders();
            this.allReplies = $(".c-Experience-cat-reply__content").length;
        })
    },    
    methods: {
        getAllReplies (fetching) {
            if (fetching) this.isFetching = true;
            this.$http.post('/api/get-blog-comment/' + this.blogId + '/').then(res => {
                const responseBody = res.body;
                console.log(responseBody);
                if (responseBody && responseBody.message == "error") {

                } else if (responseBody && responseBody.message == "success") {
                    let replies = responseBody.results || [];
                    replies.forEach(item => {
                        item.reply = item.comment;
                        item.username = item.userName;
                        item.repliedOn = item.createdOnStr;
                        item.userProfileLink = `/user-profile/${item.username}/home/`;
                    });
                    this.replies = [...replies];
                    this.$nextTick(() => {
                        if (fetching) this.isFetching = false;
                    });
                    this.$forceUpdate();
                }
            })
        },
        checkIfUserLoggedIn () {
            this.$http.post('/api/user-authentication/').then(res => {
                const responseBody = res.body;
                let usernameElm = document.getElementById('cred-username');
                let registerElm = document.getElementById('cred-register');
                let loginElm = document.getElementById('cred-login');
                let logoutElm = document.getElementById('cred-logout');

                if(responseBody && responseBody.userDetails) {
                    window.immiObj.ath = responseBody;
                    
                    if (usernameElm) usernameElm.innerHTML = `<a class="u-Underline" href="/user-profile/${responseBody.userDetails.username}/home/">${responseBody.userDetails.username}</a>`;
                    if (registerElm) registerElm.style.display = "none";
                    if (logoutElm) logoutElm.style.display = "inline-block";
                    if (loginElm) loginElm.style.display = "none";
                    if (credMainElm) credMainElm.style.display = "flex";

                } else {
                    window.immiObj.ath = {
                        isLoggedIn: false,
                        userDetails: null
                    }

                    let guestText = "Guest";
                    if (immiObj.currentLangCode == 'zh') {
                        guestText = "用户";
                    } else if (immiObj.currentLangCode == 'es') {
                        guestText = "Invitado";
                    }

                    if (usernameElm) usernameElm.innerHTML = guestText;
                    if (registerElm) registerElm.style.display = "inline-block";
                    if (logoutElm) logoutElm.style.display = "none";
                    if (loginElm) loginElm.style.display = "inline-block";
                    if (credMainElm) credMainElm.style.display = "flex";
                }
            });
        },
        closeThisReply () {
            this.toggleReply = false;
        },
        openThisReply () {
            if (window.immiObj && window.immiObj.ath && window.immiObj.ath.isLoggedIn) {
                this.toggleReply = true;
                this.$root.$emit('closeNestedReplies', this.blogId, true);
            } else {
                setItemSessionStorage(SessionKeys.blog.openAddBlog, true);
                window.location.href = RedirectsUrls.login;
            }
        },
        removeExtraBorders() {
            // Removing border bottoms for nested replies
            const $replies = document.querySelectorAll('.c-Experience-cat__reply.borderline:not([parent-id])')
            let mainreplies = [];
            if ($replies) {
                mainreplies = [...$replies];
            }
            mainreplies.forEach(item => {
                const replyId = item.getAttribute('reply-id');
                const $footers = document.querySelectorAll('.c-Experience-cat__reply[reply-id="' + replyId + '"] .c-Experience-cat-reply__footer');
                let allfooters = [];
                if ($footers && $footers.length) {
                    allfooters = [...$footers];
                    allfooters[allfooters.length - 1].style.borderBottom = 0;
                    allfooters[allfooters.length - 1].style.paddingBottom = 0;
                }
            });
        }
    }
});