import { breakpoints, globalColors } from '../globals/config';
import { getEl } from '../helpers/helpers';

(function() {

    //START MOBILE MENU
    const mobileMenu = getEl('hamburger-menu');
    const mobileBody = getEl('mobile-menu');
    const body = document.getElementsByTagName("BODY")[0];
    const desktopClassName = 'c-Header__desktop-nav';
    const mobileClassName = 'c-Header__mobile-menu';
    let debounceTimeout = null;
    window.mobileToggle = false; // Will look at reworking without window... Window is here for clickAway.js
    
    const openMenu = () => {
        window.mobileToggle = true;
        mobileBody.classList.add('mobile-open');
        mobileBody.classList.add(mobileClassName);
        mobileBody.classList.remove(desktopClassName);

        mobileMenu.classList.add('mobile-open');
        body.classList.add('pushy-open-right');
    }

    const closeMenu = () => {
        window.mobileToggle = false;
        mobileBody.classList.remove('mobile-open');
        mobileBody.classList.remove(mobileClassName);
        mobileBody.classList.add(desktopClassName);
        mobileMenu.classList.remove('mobile-open');
        body.classList.remove('pushy-open-right');
    }

    mobileMenu.addEventListener('click', () => window.mobileToggle ? closeMenu() : openMenu() );

    window.addEventListener('resize', () => {
        debounce(() => {
            window.innerWidth >= breakpoints.large ? closeMenu() : null;
        } , 100);
    });
    //END MOBILE MENU

    //START DESKTOP NAV LINKS
    const lang = document.querySelector("html").getAttribute("lang");
    let targetMenu = 'menu-main-menu';
    if (lang === 'zh-CN') {
        targetMenu = 'menu-zh-menu';
    } else if (lang == 'es_MX' || lang == "es-ES") {
        targetMenu = 'menu-es-menu';
    }
    const desktopNav = getEl(targetMenu).childNodes;

    if(window.innerWidth >= breakpoints.large) {
        //Add red border under link when on cooresponding location of website
        //finds the LI tag which matches the window href (will need reworked when urls beocme more complicated)
        const sectionValue = window.location.pathname.split('/')[1] ? '/'+window.location.pathname.split('/')[1] +'/' : '/';
        const selectedLi = Array.prototype.filter.call(desktopNav, x =>  x.nodeName === 'LI' && x.firstChild && x.firstChild.pathname === sectionValue)[0];
        if(selectedLi) selectedLi.style.borderBottom="4px solid "+globalColors.imRed;
    }
    //END LINKS
    //START PRINT SHARE LINKS
    if(getEl('print-link')) {
        try {
            getEl('print-link').addEventListener('click', () => window.print ? window.print() : alert("Print feature is not supported on your device."));            
        } catch (error) {
            console.log("Print feature is not supported or enabled on your browser.");
        }
    }
    
    const shareLink = getEl('share-link');
    const shareMenu = getEl('share-abs-menu')
    const showShareClass = 'c-Header-share-abs-wrap--show';
    const $qr = document.getElementById("qrTrigger");

    if(shareLink) {        
        shareLink.addEventListener('click', () => {            
            if(!shareMenu.classList.contains(showShareClass)) {
                if($qr) {
                    $qr.classList.remove('show-qr');
                }
                shareMenu.classList.add(showShareClass);
            }            
        });
        if ($qr) {
            $qr.addEventListener('click', () => {
                const isShowQr = $qr.classList.contains('show-qr');
                if (isShowQr) {
                    $qr.classList.remove('show-qr');
                } else {
                    $qr.classList.add('show-qr');
                }
            })
        }
    }

    let $langList = $langList = document.querySelectorAll("a[hreflang]");
    if($langList) {
        Array.prototype.forEach.call($langList, item => {
            let url = item.href.match(/^((http[s]?|ftp):\/)?\/?([^:\/\s]+)((\/\w+)*\/)([\w\-\.]+[^#?\s]+)(.*)?(#[\w\-]+)?$/);
            if (url) {
                item.href = '/' + url[6];
            }
        });
    }

    /**
     * 
     * SEARCH
     * 
     */

    /*
     * Mobile Form variables
     */
    var $mobileForm = document.getElementById("site-search-input-mobile");

    /*
     * Desktop variables
     */
    var $searchForm = document.getElementById("site-search-input");
    var searchQuery = "";
    var url = '/search/';
    var queryFields = {
        query: "",
        page: 1
    };

    /*
     * Form Submission
     */
    var formCallBackFn = function (e) {
        e.preventDefault();

        searchQuery = e.target.elements[0].value.trim();
        queryFields.query = encodeURI(searchQuery) || "";
        window.localStorage.setItem("searchquery", queryFields.query);
        window.localStorage.setItem("searchpage", 1);
        window.location.href = url;
        return false;
    };

    /*
     * Binding browser events.
     */
    if ($searchForm.addEventListener) {
        //Modern browsers
        $searchForm.addEventListener("submit", formCallBackFn, false);
        $mobileForm.addEventListener("submit", formCallBackFn, false);
    } else if ($searchForm.attachEvent) {
        //Old IE
        $searchForm.attachEvent('onsubmit', formCallBackFn);
        $mobileForm.addEventListener('onsubmit', formCallBackFn);
    }

    //Highlighting menu
    let $immiObj = window.immiObj.currentCat || "null";    
    let $currentMenu = document.querySelector("nav.navbar-collapse ul li a[data-menu='" + $immiObj + "']");
    if($currentMenu) $currentMenu.parentElement.classList.add("active");

    /**
     * Prevent Copy paste
     */

    function handleCopyCut(e) {
        // Disable copy/cut except for clipboard js buttons  
        const currentTarget = e && e.target ? e.target : null;
        if (currentTarget) {
            const noCopyMessage = `${window.immiObj.copyCutText || 'The copy and cut function is disabled.\n immihelp.com is copyright 1999-'}${new Date().getFullYear()}`;
            const classList = Array.from(currentTarget.classList) || [];
            const isNotAllowed = classList.indexOf("c-Copy-elm-allowed") === -1;
            if (e && currentTarget && currentTarget.nodeName !== 'TEXTAREA' && currentTarget.nodeName !== 'INPUT' && isNotAllowed) {
                if (typeof alert === 'function') {
                    // Try to solve Firefox NS_ERROR_UNEXPECTED issue
                    try {
                        alert(noCopyMessage);
                    } catch (e) {}
                }
                e.preventDefault();
                return false;
            }
        }
    };

    // Add c-Copy-page-allowed to allow copy paste on entire page.
    // Add c-Copy-elm-allowed to fields that can have copy paste fn.
    const isCopyAllowed = document.querySelector(".c-Copy-page-allowed");    
    if (!isCopyAllowed) {
        document.body.addEventListener('copy', handleCopyCut);
        document.body.addEventListener('cut', handleCopyCut);
    }

    let $allWechat = document.querySelectorAll(".c-Header-share-abs-child--hasQrCode");

    if ($allWechat) {        
        Array.prototype.forEach.call($allWechat, elm => {
            elm.addEventListener('click', toggleWeChatQR);
        })
    }    

    function toggleWeChatQR (e) {
        const $elm = e.currentTarget;
        let classList = $elm.classList;
        if(classList) {
            classList = [...classList];
            if(classList.indexOf('show-qr') == -1) {
                e.currentTarget.classList.add('show-qr');
            } else {
                e.currentTarget.classList.remove('show-qr');
            }
        }
    }

    /**
     * Check if user is logged in on wordpress pages.
     */
    const credMainElm = document.getElementById('cred-main');
    function isUserLoggedIn () {
        var url = '/api/user-authentication/';

        var xhr = new XMLHttpRequest();
        xhr.open("POST", url, true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.setRequestHeader('Accept', 'application/json');        
        xhr.onreadystatechange = function () {           
            if (this.readyState == 4 && this.status == 200 && xhr && xhr.responseText) {
                try {
                    let responseBody = JSON.parse(xhr.responseText);
                    let usernameElm = document.getElementById('cred-username');
                    let usernameElmMob = document.getElementById('cred-username-mob');
                    let registerElm = document.getElementById('cred-register');
                    let registerElmMob = document.getElementById('cred-register-mob');
                    let loginElm = document.getElementById('cred-login');
                    let loginElmMob = document.getElementById('cred-login-mob');
                    let logoutElm = document.getElementById('cred-logout');
                    let logoutElmMob = document.getElementById('cred-logout-mob');

                    if (responseBody && responseBody.userDetails) {
                        window.immiObj.ath = responseBody;

                        if (usernameElm) usernameElm.innerHTML = `<a class="u-Underline" href="${window.immiLang.globalLabels?.profile}${responseBody.userDetails.username}${window.immiLang.globalLabels?.home}">${responseBody.userDetails.username}</a>`;
                        if (registerElm) registerElm.style.display = "none";
                        if (logoutElm) logoutElm.style.display = "inline-block";
                        if (loginElm) loginElm.style.display = "none";
                        if (credMainElm) credMainElm.style.display = "flex";

                        if (usernameElmMob) usernameElmMob.innerHTML = `<a class="u-Underline" href="${window.immiLang.globalLabels?.profile}${responseBody.userDetails.username}${window.immiLang.globalLabels?.home}">${responseBody.userDetails.username}</a>`;
                        if (registerElmMob) registerElmMob.style.display = "none";
                        if (logoutElmMob) logoutElmMob.style.display = "inline-block";
                        if (loginElmMob) loginElmMob.style.display = "none";

                    } else {
                        window.immiObj.ath = {
                            isLoggedIn: false,
                            userDetails: null
                        }

                        let guestText = "Guest";
                        if (immiObj.currentLangCode == 'zh' || immiObj.currentLanguage == 'Chinese') {
                            guestText = "用户";
                        } else if (immiObj.currentLangCode == 'es' || immiObj.currentLanguage == 'Spanish') {
                            guestText = "Invitado";
                        }

                        if (usernameElm) usernameElm.innerHTML = guestText;
                        if (registerElm) registerElm.style.display = "inline-block";
                        if (logoutElm) logoutElm.style.display = "none";
                        if (loginElm) loginElm.style.display = "inline-block";
                        if (credMainElm) credMainElm.style.display = "flex";

                        if (usernameElmMob) usernameElmMob.innerHTML = guestText;
                        if (registerElmMob) registerElmMob.style.display = "inline-block";
                        if (logoutElmMob) logoutElmMob.style.display = "none";
                        if (loginElmMob) loginElmMob.style.display = "inline-block";
                    }

                    var tokenInterval = null;
                    var countToStop = 0;
                    tokenInterval = setInterval(() => {
                        ++countToStop;
                        if (countToStop >= 8) {
                            clearInterval(tokenInterval);
                        }
                        try {
                            if (window.hasOwnProperty('readyToSendToken')) {
                                if (typeof window.readyToSendToken !== 'undefined' && window.readyToSendToken !== 'no') {
                                    sendTokenToServer();
                                    clearInterval(tokenInterval);
                                }
                            }
                        } catch (error) {
                            const err = error;
                            console.log(err);
                            clearInterval(tokenInterval);
                        }
                    }, 15000);
                } catch (errr) {
                    
                }
            }
        };
        xhr.send();
    }
    isUserLoggedIn();

    function debounce (fn, timmer) {
        clearTimeout(debounceTimeout);
        debounceTimeout = setTimeout(() => {
            fn();
        }, timmer);
    }
})();