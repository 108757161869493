import { getSelector, generateRandomNumber } from '../helpers/helpers';

    let folderLocation = {
        rootFolder: window.immiObj.prefixUrl,
        VisitorsToUSA: 'visitors-to-usa',
        StudentVisa: 'international-student',
        ExchangeVisitor: 'exchange-visitor',
        GroupTravel: 'group-travel',
        MultiTrip: 'multi-trip',
        NewImmigrant: 'new-immigrants',
        SchengenVisa: 'schengen-visa',
        TravelOutside: 'travel-outside-usa',
        BusinessVisa: 'visitors-to-usa/business-ads',
        YoungAdult: 'visitors-to-usa/young-adult-mix',
        Flight: 'flight',
        Marine: 'marine',
        TripCancellation: 'trip-cancellation',
        India: 'india'
    };

    let insubuyPath = {
        rootPath: 'https://www.insubuy.com/',
        en: {
            VisitorsToUSA: 'visitors-insurance',
            StudentVisa: 'international-student-insurance',
            ExchangeVisitor: 'j1-visa-health-insurance',
            GroupTravel: 'group-travel-medical-insurance',
            MultiTrip: 'multi-trip-travel-insurance',
            NewImmigrant: 'new-immigrants-health-insurance',
            SchengenVisa: 'schengen-visa-insurance',
            TravelOutside: 'travel-health-insurance',
            BusinessVisa: 'visitors-insurance',
            YoungAdult: 'visitors-insurance',
            Flight: 'flight-insurance',
            Marine: 'marine-crew-health-insurance',
            TripCancellation: 'travel-insurance',
            India: 'india-travel-insurance'
        },
        es: {
            VisitorsToUSA: 'seguro-medico-para-visitantes-a-los-estados-unidos',
            StudentVisa: 'seguro-de-estudiante-internacional',
            ExchangeVisitor: 'seguro-j1-visa',
            GroupTravel: 'group-travel-medical-insurance',
            MultiTrip: 'multi-trip-travel-insurance',
            NewImmigrant: 'seguro-de-salud-para-inmigrantes-nuevos',
            SchengenVisa: 'seguro-para-visado-schengen',
            TravelOutside: 'seguro-de-salud-de-viaje',
            BusinessVisa: 'seguro-medico-para-visitantes-a-los-estados-unidos',
            YoungAdult: 'seguro-medico-para-visitantes-a-los-estados-unidos',
            TripCancellation: 'travel-insurance'
        },
        zh: {
            VisitorsToUSA: 'meiguo-lvyou-yiliao-baoxian',
            StudentVisa: 'guoji-xuesheng-baoxian',
            ExchangeVisitor: 'j1-qianzheng-baoxian',
            GroupTravel: 'tuanti-lvyou-yiliao-baoxian',
            MultiTrip: 'multi-trip-travel-insurance',
            NewImmigrant: 'xin-yimin-jiankang-baoxian',
            SchengenVisa: 'shengen-qianzheng-baoxian',
            TravelOutside: 'lvyou-jiankang-baoxian',
            BusinessVisa: 'meiguo-lvyou-yiliao-baoxian',
            YoungAdult: 'meiguo-lvyou-yiliao-baoxian',
            TripCancellation: 'travel-insurance'
        }
    };

    let selectedCategories = [];
    let categoryAds = window.immiObj.categoryAdsList && typeof window.immiObj.categoryAdsList === 'string' ? window.immiObj.categoryAdsList.split(',') : window.immiObj.categoryAdsList && Array.isArray(window.immiObj.categoryAdsList) ? window.immiObj.categoryAdsList : [];
    let language = 'en';
    if (window.immiObj.currentLanguage === 'Chinese' || (window.immiLang && window.immiLang.currentLangCode == 'zh')) {
        language = 'zh';    
    } else if(window.immiObj.currentLanguage === 'Spanish') {
        language = 'es';
    }
    
    for (let i = 0 ; i < categoryAds.length ; i++) {
        if (categoryAds[i] === 'Visitors To USA' || categoryAds[i] === 'VisitorsToUSA') {
            selectedCategories.push('VisitorsToUSA');
        } else if (categoryAds[i] === 'Student Visa (F1/F2)' || categoryAds[i] === 'StudentVisa') {
            selectedCategories.push('StudentVisa');
        } else if (categoryAds[i] === 'Exchange Visitor (J1/J2)' || categoryAds[i] === 'ExchangeVisitor') {
            selectedCategories.push('ExchangeVisitor');
        } else if (categoryAds[i] === 'Group Travel' || categoryAds[i] === 'GroupTravel') {
            selectedCategories.push('GroupTravel');
        } else if (categoryAds[i] === 'Multi Trip' || categoryAds[i] === 'MultiTrip') {
            selectedCategories.push('MultiTrip');
        } else if (categoryAds[i] === 'New Immigrants' || categoryAds[i] === 'NewImmigrant') {
            selectedCategories.push('NewImmigrant');
        } else if (categoryAds[i] === 'Schengen Visa' || categoryAds[i] === 'SchengenVisa') {
            selectedCategories.push('SchengenVisa');
        } else if (categoryAds[i] === 'Travel Outside USA' || categoryAds[i] === 'TravelOutside') {
            selectedCategories.push('TravelOutside');
        } else if (categoryAds[i] === 'Business Visa (Visitors Visa)' || categoryAds[i] === 'BusinessVisa') {
            selectedCategories.push('BusinessVisa');
        } else if (categoryAds[i] === 'Young Adult (Visitors Visa)' || categoryAds[i] === 'YoungAdult') {
            selectedCategories.push('YoungAdult');
        } else if (categoryAds[i] === 'Flight' || categoryAds[i] === 'Flight') {
            selectedCategories.push('Flight');
        } else if (categoryAds[i] === 'Marine') {
            selectedCategories.push('Marine');
        } else if (categoryAds[i] === 'Trip Cancellation' || categoryAds[i] === 'TripCancellation') {
            selectedCategories.push('TripCancellation');
        } else if (categoryAds[i] === 'India') {
            selectedCategories.push('India');
        }
    }
    if(selectedCategories.length === 0) {
        selectedCategories = ['VisitorsToUSA', 'StudentVisa', 'ExchangeVisitor'];
    }

    function ListOfEnglishBanners () {

        // Visitors To USA
        this.VisitorsToUSA = {
            $desktop_vertical: ['visitors_to_usa-v-1.jpg', 'visitors_to_usa-v-2.jpg', 'visitors_to_usa-v-3.gif'],
            $desktop_horizontal: ['visitors_to_usa-h-1.jpg', 'visitors_to_usa-h-2.gif' ,'visitors_to_usa-h-3.gif', 'visitors_to_usa-h-4.gif'],
            $desktop_square: ['visitors_to_usa-s-1.jpg', 'visitors_to_usa-s-2.gif', 'visitors_to_usa-s-3.gif'],
            $mobile_top: ['visitors_to_usa-m-t-1.gif', 'visitors_to_usa-m-t-2.gif', 'visitors_to_usa-m-t-3.jpg'],
            $mobile_bottom: ['visitors_to_usa-m-b-1.jpg', 'visitors_to_usa-m-b-2.gif']
        };

        // Student Visa
        this.StudentVisa = {
            $desktop_vertical: ['student-v-1.jpg', 'student-v-2.jpg', 'student-v-3.gif'],
            $desktop_horizontal: ['student-h-1.jpg', 'student-h-2.jpg', 'student-h-3.gif'],
            $desktop_square: ['student-s-1.jpg', 'student-s-2.jpg', 'student-s-3.jpg'],
            $mobile_top: ['student-m-t-1.png', 'student-m-t-2.gif', 'student-m-t-3.gif'],
            $mobile_bottom: ['student-m-b-1.jpg', 'student-m-b-2.jpg', 'student-m-b-3.jpg']
        };

        // Exchange Visitor
        this.ExchangeVisitor = {
            $desktop_vertical: ['j1_visa-v-1.jpg', 'j1_visa-v-2.gif', 'j1_visa-v-3.gif'],
            $desktop_horizontal: ['j1_visa-h-1.jpg', 'j1_visa-h-2.gif', 'j1_visa-h-3.gif'],
            $desktop_square: ['j1_visa-s-1.jpg', 'j1_visa-s-2.jpg'],
            $mobile_top: ['j1_visa-m-t-1.jpg', 'j1_visa-m-t-2.gif', 'j1_visa-m-t-3.gif'],
            $mobile_bottom: ['j1_visa-m-b-1.jpg', 'j1_visa-m-b-2.gif', 'j1_visa-m-b-3.gif']
        };

        // Group Travel
        this.GroupTravel = {
            $desktop_vertical: ['group_travel-v-1.jpg', 'group_travel-v-2.jpg', 'group_travel-v-3.jpg'],
            $desktop_horizontal: ['group_travel-h-1.jpg', 'group_travel-h-2.jpg', 'group_travel-h-2.jpg'],
            $desktop_square: ['group_travel-s-1.jpg', 'group_travel-s-2.jpg'],
            $mobile_top: ['group_travel-m-t-1.jpg', 'group_travel-m-t-2.gif', 'group_travel-m-t-3.gif'],
            $mobile_bottom: ['group_travel-m-b-1.jpg', 'group_travel-m-b-2.jpg', 'group_travel-m-b-3.jpg']
        };

        // Multi Trip
        this.MultiTrip = {
            $desktop_vertical: ['multitrip-v-1.jpg'],
            $desktop_horizontal: ['multitrip-h-1.jpg'],
            $desktop_square: ['multitrip-s-1.jpg'],
            $mobile_top: ['multitrip-m-t-1.jpg'],
            $mobile_bottom: ['multitrip-m-b-1.jpg']
        };
        

        this.NewImmigrant = {
            $desktop_vertical: ['new_immigrants-v-1.gif', 'new_immigrants-v-2.jpg', 'new_immigrants-v-3.gif'],
            $desktop_horizontal: ['new_immigrants-h-1.gif', 'new_immigrants-h-2.gif', 'new_immigrants-h-3.gif'],
            $desktop_square: ['new_immigrants-s-1.gif', 'new_immigrants-s-2.gif'],
            $mobile_top: ['new_immigrants-m-t-1.gif', 'new_immigrants-m-t-2.gif'],
            $mobile_bottom: ['new_immigrants-m-b-1.gif', 'new_immigrants-m-b-2.gif']
        };

        this.India = {
            $desktop_vertical: [],
            $desktop_horizontal: ['india-h-1.jpg'],
            $desktop_square: [],
            $mobile_top: ['india-m-t-2.gif'],
            $mobile_bottom: []
        };

        // Schengen Visa
        this.SchengenVisa = {
            $desktop_vertical: ['schengen_visa-v-1.gif', 'schengen_visa-v-2.jpg', 'schengen_visa-v-3.jpg'],
            $desktop_horizontal: ['schengen_visa-h-1.gif', 'schengen_visa-h-2.gif', 'schengen_visa-h-3.gif', 'schengen_visa-h-4.jpg'],
            $desktop_square: ['schengen_visa-s-1.jpg', 'schengen_visa-s-2.gif', 'schengen_visa-s-3.gif'],
            $mobile_top: ['schengen_visa-m-t-1.jpg', 'schengen_visa-m-t-2.gif', 'schengen_visa-m-t-3.gif'],
            $mobile_bottom: ['schengen_visa-m-b-1.jpg', 'schengen_visa-m-b-2.gif', 'schengen_visa-m-b-3.gif']
        };

        // Travel Outside USA
        this.TravelOutside = {
            $desktop_vertical: ['travel_insurance_outside_usa-v-1.gif', 'travel_insurance_outside_usa-v-2.jpg', 'travel_insurance_outside_usa-v-3.jpg', 'travel_insurance_outside_usa-v-4.jpg'],
            $desktop_horizontal: ['travel_insurance_outside_usa-h-1.gif', 'travel_insurance_outside_usa-h-2.gif', 'travel_insurance_outside_usa-h-3.gif', 'travel_insurance_outside_usa-h-4.jpg'],
            $desktop_square: ['travel_insurance_outside_usa-s-1.gif', 'travel_insurance_outside_usa-s-2.gif', 'travel_insurance_outside_usa-s-3.gif', 'travel_insurance_outside_usa-s-4.gif', 'travel_insurance_outside_usa-s-5.jpg'],
            $mobile_top: ['travel_insurance_outside_usa-m-t-1.gif', 'travel_insurance_outside_usa-m-t-2.gif', 'travel_insurance_outside_usa-m-t-3.jpg'],
            $mobile_bottom: ['travel_insurance_outside_usa-m-b-1.gif', 'travel_insurance_outside_usa-m-b-2.gif', 'travel_insurance_outside_usa-m-b-3.gif', 'travel_insurance_outside_usa-m-b-4.gif', 'travel_insurance_outside_usa-m-b-5.jpg']
        };
        
        // Business Visa
        this.BusinessVisa = {
            $desktop_vertical: ['visitors_to_usa_business-v-1.gif', 'visitors_to_usa_business-v-2.jpg', 'visitors_to_usa_business-v-3.jpg'],
            $desktop_horizontal: ['visitors_to_usa_business-h-1.gif', 'visitors_to_usa_business-h-2.jpg', 'visitors_to_usa_business-h-3.gif', 'visitors_to_usa_business-h-4.gif'],
            $desktop_square: ['visitors_to_usa_business-s-1.jpg'],
            $mobile_top: ['visitors_to_usa_business-m-t-1.gif', 'visitors_to_usa_business-m-t-2.gif', 'visitors_to_usa_business-m-t-3.jpg'],
            $mobile_bottom: ['visitors_to_usa_business-m-b-1.jpg', 'visitors_to_usa_business-m-b-2.jpg', 'visitors_to_usa_business-m-b-3.jpg']
        };
        
        // Young Adult
        this.YoungAdult = {
            $desktop_vertical: ['visitors_to_usa_young_adult_mix-v-1.jpg', 'visitors_to_usa_young_adult_mix-v-2.gif', 'visitors_to_usa_young_adult_mix-v-3.jpg'],
            $desktop_horizontal: ['visitors_to_usa_young_adult_mix-h-1.gif', 'visitors_to_usa_young_adult_mix-h-2.jpg', 'visitors_to_usa_young_adult_mix-h-3.gif', 'visitors_to_usa_young_adult_mix-h-4.gif'],
            $desktop_square: ['visitors_to_usa_young_adult_mix-s-1.jpg'],
            $mobile_top: ['visitors_to_usa_young_adult_mix-m-t-1.jpg', 'visitors_to_usa_young_adult_mix-m-t-2.jpg', 'visitors_to_usa_young_adult_mix-m-t-3.gif', 'visitors_to_usa_young_adult_mix-m-t-4.gif'],
            $mobile_bottom: ['visitors_to_usa_young_adult_mix-m-b-1.jpg', 'visitors_to_usa_young_adult_mix-m-b-2.jpg', 'visitors_to_usa_young_adult_mix-m-b-3.jpg']
        };

        this.Flight = {
            $desktop_vertical: ['flight-v-1.jpg'],
            $desktop_horizontal: ['flight-h-1.jpg'],
            $desktop_square: ['flight-s-1.jpg'],
            $mobile_top: ['flight-m-t-1.jpg'],
            $mobile_bottom: ['flight-m-b-1.jpg']
        }

        // Marine
        this.Marine = {
            $desktop_vertical: ['marine-crew-v-1.jpg', 'marine-crew-v-2.jpg', 'marine-crew-v-3.jpg'],
            $desktop_horizontal: ['marine-crew-h-1.jpg', 'marine-crew-h-2.jpg', 'marine-crew-h-3.jpg'],
            $desktop_square: ['marine-crew-s-1.jpg', 'marine-crew-s-2.jpg', 'marine-crew-s-3.jpg'],
            $mobile_top: ['marine-crew-m-t-1.gif', 'marine-crew-m-t-2.jpg', 'marine-crew-m-t-3.jpg'],
            $mobile_bottom: ['marine-crew-m-b-1.jpg', 'marine-crew-m-b-2.jpg', 'marine-crew-m-b-3.jpg']
        }

        // Trip Cancellation
        this.TripCancellation = {
            $desktop_vertical: ['travel_insurance_banner1_v.jpg', 'travel_insurance_banner3_v.jpg', 'travel_insurance_banner4_v.gif', 'travel_insurance_banner5_v.jpg', 'travel_insurance_banner7_v.jpg', 'travel_insurance_banner8_v.jpg', 'travel_insurance_banner9_v.jpg'],
            $desktop_horizontal: ['travel_insurance_h-1.jpg', 'travel_insurance_h-2.jpg', 'travel_insurance_h-3.gif', 'travel_insurance_h-4.jpg', 'travel_insurance_h-5.jpg', 'travel_insurance_h-6.jpg', 'travel_insurance_h-7.jpg'],
            $desktop_square: ['travel_insurance_outside_usa_s-1.gif', 'travel_insurance_s-1.jpg', 'travel_insurance_s-2.jpg'],
            $mobile_top: ['travel_insurance_m-t-1.gif', 'travel_insurance_m-t-2.gif'],
            $mobile_bottom: ['travel_insurance_m-b-1.jpg', 'travel_insurance_m-b-2.gif', 'travel_insurance_m-b-3.jpg'],
        }
    }

    function ListOfSpanishBanners () {

        // Visitors To USA
        this.VisitorsToUSA = {
            $desktop_vertical: ['visitors_to_usa-v-1.jpg', 'visitors_to_usa-v-2.gif', 'visitors_to_usa-v-3.jpg'],
            $desktop_horizontal: ['visitors_to_usa-h-1.jpg', 'visitors_to_usa-h-2.gif' ,'visitors_to_usa-h-3.gif'],
            $desktop_square: ['visitors_to_usa-s-1.jpg', 'visitors_to_usa-s-2.gif', 'visitors_to_usa-s-3.gif', 'visitors_to_usa-s-4.jpg', 'visitors_to_usa-s-5.jpg', 'visitors_to_usa-s-6.gif'],
            $mobile_top: ['visitors_to_usa-m-t-1.gif', 'visitors_to_usa-m-t-2.jpg', 'visitors_to_usa-m-t-3.gif'],
            $mobile_bottom: ['visitors_to_usa-m-b-1.jpg', 'visitors_to_usa-m-b-2.gif', 'visitors_to_usa-m-b-3.gif']
        };

        // Student Visa
        this.StudentVisa = {
            $desktop_vertical: ['student-v-1.jpg', 'student-v-2.jpg', 'student-v-3.gif'],
            $desktop_horizontal: ['student-h-1.jpg', 'student-h-2.jpg', 'student-h-3.gif'],
            $desktop_square: ['student-s-1.jpg', 'student-s-2.jpg', 'student-s-3.gif', 'student-s-4.jpg','student-s-5.gif', 'student-s-6.gif'],
            $mobile_top: ['student-m-t-1.jpg', 'student-m-t-2.gif', 'student-m-t-3.gif'],
            $mobile_bottom: ['student-m-b-1.jpg', 'student-m-b-2.jpg', 'student-m-b-3.jpg']
        };

        // Exchange Visitor
        this.ExchangeVisitor = {
            $desktop_vertical: ['j1_visa-v-1.png', 'j1_visa-v-2.gif', 'j1_visa-v-3.gif'],
            $desktop_horizontal: ['j1_visa-h-1.png', 'j1_visa-h-2.gif', 'j1_visa-h-3.gif'],
            $desktop_square: ['j1_visa-s-1.jpg', 'j1_visa-s-2.gif', 'j1_visa-s-3.gif', 'j1_visa-s-4.jpg', 'j1_visa-s-5.gif', 'j1_visa-s-6.gif'],
            $mobile_top: ['j1_visa-m-t-1.jpg', 'j1_visa-m-t-2.gif', 'j1_visa-m-t-3.gif'],
            $mobile_bottom: ['j1_visa-m-b-1.jpg', 'j1_visa-m-b-2.gif', 'j1_visa-m-b-3.gif']
        };

        // Group Travel
        this.GroupTravel = {
            $desktop_vertical: ['group_travel-v-1.jpg', 'group_travel-v-2.jpg'],
            $desktop_horizontal: ['group_travel-h-1.jpg', 'group_travel-h-2.jpg'],
            $desktop_square: ['group_travel-s-1.jpg', 'group_travel-s-2.jpg'],
            $mobile_top: ['group_travel-m-t-1.gif', 'group_travel-m-t-2.gif'],
            $mobile_bottom: ['group_travel-m-b-1.jpg', 'group_travel-m-b-2.jpg']
        };

        // Multi Trip
        this.MultiTrip = {
            $desktop_vertical: ['multitrip-v-1.jpg'],
            $desktop_horizontal: ['multitrip-h-1.jpg'],
            $desktop_square: ['multitrip-s-1.jpg'],
            $mobile_top: ['multitrip-m-t-1.jpg'],
            $mobile_bottom: ['multitrip-m-b-1.jpg']
        };

        // New Immigrants
        this.NewImmigrant = {
            $desktop_vertical: ['new_immigrant_v-1.jpg', 'new_immigrant_v-2.jpg', 'new_immigrant_v-3.gif'],
            $desktop_horizontal: ['new_immigrants_h-1.jpg', 'new_immigrants_h-2.gif', 'new_immigrants_h-3.jpg'],
            $desktop_square: ['new_immigrants_s-1.jpg', 'new_immigrants_s-2.jpg'],
            $mobile_top: ['new_immigrants_m-t-1.gif', 'new_immigrants_m-t-2.gif'],
            $mobile_bottom: ['new_immigrants_m-b-1.jpg', 'new_immigrants_m-b-2.jpg']
        };

        // Schengen Visa
        this.SchengenVisa = {
            $desktop_vertical: ['schengen_visa-v-1.jpg', 'schengen_visa-v-2.jpg'],
            $desktop_horizontal: ['schengen_visa-h-1.jpg', 'schengen_visa-h-2.gif', 'schengen_visa-h-3.jpg'],
            $desktop_square: ['schengen_visa-s-1.jpg', 'schengen_visa-s-2.jpg'],
            $mobile_top: ['schengen_visa-m-t-1.gif', 'schengen_visa-m-t-2.gif'],
            $mobile_bottom: ['schengen_visa-m-b-1.jpg', 'schengen_visa-m-b-2.jpg']
        };

        // Travel Outside USA
        this.TravelOutside = {
            $desktop_vertical: ['travel_insurance_outside_usa-v-1.jpg', 'travel_insurance_outside_usa-v-2.jpg', 'travel_insurance_outside_usa-v-3.jpg'],
            $desktop_horizontal: ['travel_insurance_outside_usa-h-1.jpg', 'travel_insurance_outside_usa-h-2.gif', 'travel_insurance_outside_usa-h-3.jpg'],
            $desktop_square: ['travel_insurance_outside_usa-s-1.jpg', 'travel_insurance_outside_usa-s-2.jpg', 'travel_insurance_outside_usa-s-3.gif', 'travel_insurance_outside_usa-s-4.jpg'],
            $mobile_top: ['travel_insurance_outside_usa-m-t-1.gif', 'travel_insurance_outside_usa-m-t-2.gif'],
            $mobile_bottom: ['travel_insurance_outside_usa-m-b-1.jpg', 'travel_insurance_outside_usa-m-b-2.jpg', 'travel_insurance_outside_usa-m-b-3.jpg']
        };
        
        // Business Visa
        this.BusinessVisa = {
            $desktop_vertical: [],
            $desktop_horizontal: [],
            $desktop_square: [],
            $mobile_top: [],
            $mobile_bottom: []
        };
        
        // Young Adult
        this.YoungAdult = {
            $desktop_vertical: [],
            $desktop_horizontal: [],
            $desktop_square: [],
            $mobile_top: [],
            $mobile_bottom: []
        };

        //flight
        this.Flight = {
            $desktop_vertical: ['flight-v-1.jpg'],
            $desktop_horizontal: ['flight-h-1.jpg'],
            $desktop_square: ['flight-s-1.jpg'],
            $mobile_top: ['flight-m-t-1.jpg'],
            $mobile_bottom: ['flight-m-b-1.jpg']
        }

        this.Marine = {
            $desktop_vertical: [],
            $desktop_horizontal: [],
            $desktop_square: [],
            $mobile_top: [],
            $mobile_bottom: []
        }

        // Trip Cancellation
        this.TripCancellation = {
            $desktop_vertical: [],
            $desktop_horizontal: [],
            $desktop_square: [],
            $mobile_top: [],
            $mobile_bottom: []
        }
    }

    function ListOfChineseBanners () {

        // Visitors To USA
        this.VisitorsToUSA = {
            $desktop_vertical: ['visitors_to_usa-v-1.jpg', 'visitors_to_usa-v-2.gif', 'visitors_to_usa-v-3.jpg'],
            $desktop_horizontal: ['visitors_to_usa-h-1.gif', 'visitors_to_usa-h-2.gif', 'visitors_to_usa-h-3.gif'],
            $desktop_square: ['visitors_to_usa-s-1.gif', 'visitors_to_usa-s-2.gif', 'visitors_to_usa-s-3.gif'],
            $mobile_top: ['visitors_to_usa-m-t-1.jpg', 'visitors_to_usa-m-t-2.gif', 'visitors_to_usa-m-t-3.gif'],
            $mobile_bottom: ['visitors_to_usa-m-b-1.gif', 'visitors_to_usa-m-b-2.jpg']
        };        

        // Student Visa
        this.StudentVisa = {
            $desktop_vertical: ['student-v-1.png', 'student-v-2.jpg', 'student-v-3.gif'],
            $desktop_horizontal: ['student-h-1.png', 'student-h-2.jpg', 'student-h-3.gif'],
            $desktop_square: ['student-s-1.jpg', 'student-s-2.jpg', 'student-s-3.jpg', 'student-s-4.jpg'],
            $mobile_top: ['student-m-t-1.png', 'student-m-t-2.gif', 'student-m-t-3.gif'],
            $mobile_bottom: ['student-m-b-1.jpg', 'student-m-b-2.jpg', 'student-m-b-3.jpg']
        };        

        // Exchange Visitor
        this.ExchangeVisitor = {
            $desktop_vertical: ['j1_visa-v-1.jpg', 'j1_visa-v-2.gif', 'j1_visa-v-3.gif'],
            $desktop_horizontal: ['j1_visa-h-1.jpg', 'j1_visa-h-2.gif', 'j1_visa-h-3.gif'],
            $desktop_square: ['j1_visa-s-1.jpg', 'j1_visa-s-2.jpg'],
            $mobile_top: ['j1_visa-m-t-1.jpg', 'j1_visa-m-t-2.jpg', 'j1_visa-m-t-3.gif', 'j1_visa-m-t-4.gif'],
            $mobile_bottom: ['j1_visa-m-b-1.jpg']
        };        

        // Group Travel
        this.GroupTravel = {
            $desktop_vertical: ['group_travel-v-1.jpg', 'group_travel-v-2.jpg', 'group_travel-v-3.jpg'],
            $desktop_horizontal: ['group_travel-h-1.jpg', 'group_travel-h-2.jpg', 'group_travel-h-3.jpg'],
            $desktop_square: ['group_travel-s-1.jpg', 'group_travel-s-2.jpg', 'group_travel-s-3.jpg'],
            $mobile_top: ['group_travel-m-t-1.gif', 'group_travel-m-t-2.gif', 'group_travel-m-t-3.jpg'],
            $mobile_bottom: ['group_travel-m-b-1.jpg', 'group_travel-m-b-2.jpg', 'group_travel-m-b-3.jpg']
        };

        // Multi Trip
        this.MultiTrip = {
            $desktop_vertical: ['multitrip-v-1.jpg'],
            $desktop_horizontal: ['multitrip-h-1.jpg'],
            $desktop_square: ['multitrip-s-1.jpg'],
            $mobile_top: ['multitrip-m-t-1.jpg'],
            $mobile_bottom: ['multitrip-m-b-1.jpg']
        };

        // New Immigrants
        this.NewImmigrant = {
            $desktop_vertical: ['new_immigrant-v-1.jpg', 'new_immigrant-v-2.jpg', 'new_immigrant-v-3.gif'],
            $desktop_horizontal: ['new_immigrant-h-1.jpg', 'new_immigrant-h-2.gif', 'new_immigrant-h-3.jpg'],
            $desktop_square: ['new_immigrant-s-1.jpg', 'new_immigrant-s-2.jpg'],
            $mobile_top: ['new_immigrant-m-t-1.gif', 'new_immigrant-m-t-1.gif'],
            $mobile_bottom: ['new_immigrant-m-b-1.jpg', 'new_immigrant-m-b-2.jpg']
        };

        // Schengen Visa
        this.SchengenVisa = {
            $desktop_vertical: ['schengen_visa-v-1.gif', 'schengen_visa-v-2.png', 'schengen_visa-v-3.jpg'],
            $desktop_horizontal: ['schengen_visa-h-1.gif', 'schengen_visa-h-2.gif', 'schengen_visa-h-3.jpg'],
            $desktop_square: ['schengen_visa-s-1.gif', 'schengen_visa-s-2.gif', 'schengen_visa-s-3.gif', 'schengen_visa-s-4.gif', 'schengen_visa-s-5.jpg'],
            $mobile_top: ['schengen_visa-m-t-1.jpg', 'schengen_visa-m-t-2.gif', 'schengen_visa-m-t-3.gif'],
            $mobile_bottom: ['schengen_visa-m-b-1.gif', 'schengen_visa-m-b-2.jpg', 'schengen_visa-m-b-3.gif']
        };

        // Travel Outside USA
        this.TravelOutside = {
            $desktop_vertical: ['travel_insurance_outside_usa-v-1.gif', 'travel_insurance_outside_usa-v-2.jpg', 'travel_insurance_outside_usa-v-3.jpg'],
            $desktop_horizontal: ['travel_insurance_outside_usa-h-1.gif', 'travel_insurance_outside_usa-h-2.gif', 'travel_insurance_outside_usa-h-3.gif'],
            $desktop_square: ['travel_insurance_outside_usa-s-1.gif', 'travel_insurance_outside_usa-s-2.jpg'],
            $mobile_top: ['travel_insurance_outside_usa-m-t-1.gif', 'travel_insurance_outside_usa-m-t-2.gif'],
            $mobile_bottom: ['travel_insurance_outside_usa-m-b-1.gif', 'travel_insurance_outside_usa-m-b-2.gif', 'travel_insurance_outside_usa-m-b-3.gif']
        };

        // Business Visa
        this.BusinessVisa = {
            $desktop_vertical: ['visitors_to_usa_business-v-1.jpg', 'visitors_to_usa_business-v-2.gif', 'visitors_to_usa_business-v-3.jpg'],
            $desktop_horizontal: ['visitors_to_usa_business-h-1.jpg', 'visitors_to_usa_business-h-2.gif', 'visitors_to_usa_business-h-3.gif'],
            $desktop_square: ['visitors_to_usa_business-s-1.jpg', 'visitors_to_usa_business-s-2.gif', 'visitors_to_usa_business-s-3.gif', 'visitors_to_usa_business-s-4.jpg', 'visitors_to_usa_business-s-5.gif', 'visitors_to_usa_business-s-6.gif'],
            $mobile_top: ['visitors_to_usa_business-m-t-1.jpg', 'visitors_to_usa_business-m-t-2.gif', 'visitors_to_usa_business-m-t-3.gif'],
            $mobile_bottom: ['visitors_to_usa_business-m-b-1.jpg', 'visitors_to_usa_business-m-b-2.jpg', 'visitors_to_usa_business-m-b-3.jpg']
        };

        // Young Adult
        this.YoungAdult = {
            $desktop_vertical: ['young_adult_mix-v-1.jpg', 'young_adult_mix-v-2.gif', 'young_adult_mix-v-3.jpg'],
            $desktop_horizontal: [],
            $desktop_square: [],
            $mobile_top: ['young_adult_mix-m-t-1.gif', 'young_adult_mix-m-t-2.gif', 'young_adult_mix-m-t-3.jpg'],
            $mobile_bottom: ['young_adult_mix-m-b-1.jpg', 'young_adult_mix-m-b-2.jpg', 'young_adult_mix-m-b-3.jpg']
        };

        //flight
        this.Flight = {
            $desktop_vertical: ['flight-v-1.jpg'],
            $desktop_horizontal: ['flight-h-1.jpg'],
            $desktop_square: ['flight-s-1.jpg'],
            $mobile_top: ['flight-m-t-1.jpg'],
            $mobile_bottom: ['flight-m-b-1.jpg']
        }

        this.Marine = {
            $desktop_vertical: [],
            $desktop_horizontal: [],
            $desktop_square: [],
            $mobile_top: [],
            $mobile_bottom: []
        }

        // Trip Cancellation
        this.TripCancellation = {
            $desktop_vertical: [],
            $desktop_horizontal: [],
            $desktop_square: [],
            $mobile_top: [],
            $mobile_bottom: []
        }
    }

    let englishBanners = new ListOfEnglishBanners();
    
    if(language == 'zh') {
        englishBanners = new ListOfChineseBanners();
        folderLocation.rootFolder += 'zh/';
    }

    if(language == 'es') {
        englishBanners = new ListOfSpanishBanners();
        folderLocation.rootFolder += 'es/';
    }

    let verticalBanners = [];
    let horizontalBanners = [];
    let squareBanners = [];
    let mobileTopBanners = [];
    let mobileBottomBanners = [];

    for(let i = 0 ; i < selectedCategories.length ; i++) {

        if (selectedCategories.length > 1 && selectedCategories.indexOf('VisitorsToUSA') > -1 && selectedCategories[i] != 'VisitorsToUSA') {
            // Splicing up all the images in the array and keep only one so that visitors ads are targeted more than others.
            if(selectedCategories.length > 2)  {
                englishBanners[selectedCategories[i]]["$desktop_vertical"].splice(1, (englishBanners[selectedCategories[i]]["$desktop_vertical"].length - 1));
            } else {
                englishBanners[selectedCategories[i]]["$desktop_vertical"].splice(2, (englishBanners[selectedCategories[i]]["$desktop_vertical"].length - 1));
            }
        }
        
        // Setting Folderpath
        englishBanners[ selectedCategories[i] ]["$desktop_vertical"] = englishBanners[ selectedCategories[i] ]["$desktop_vertical"].map((e,index)=> {
            let imgPath = folderLocation.rootFolder+folderLocation[selectedCategories[i]]+'/'+e;
            let navigation = insubuyPath.rootPath + insubuyPath[language][selectedCategories[i]] +'/?utm_source=immihelp&utm_medium=' + selectedCategories[i] + '&utm_campaign=Vertical' + (index+1);
            return {
                imgPath: imgPath,
                navigation: navigation
            };
        });
        englishBanners[ selectedCategories[i] ]["$desktop_horizontal"] = englishBanners[ selectedCategories[i] ]["$desktop_horizontal"].map((e, index) => {
            let imgPath = folderLocation.rootFolder+folderLocation[selectedCategories[i]]+'/'+e
            let navigation = insubuyPath.rootPath + insubuyPath[language][selectedCategories[i]] + '/?utm_source=immihelp&utm_medium=' + selectedCategories[i] + '&utm_campaign=Horizontal' + (index+1);
            return {
                imgPath: imgPath,
                navigation: navigation
            };
        });
        englishBanners[ selectedCategories[i] ]["$desktop_square"] = englishBanners[ selectedCategories[i] ]["$desktop_square"].map((e, index) => {
            let imgPath = folderLocation.rootFolder+folderLocation[selectedCategories[i]]+'/'+e
            let navigation = insubuyPath.rootPath + insubuyPath[language][selectedCategories[i]] + '/?utm_source=immihelp&utm_medium=' + selectedCategories[i] + '&utm_campaign=square' + (index+1);
            return {
                imgPath: imgPath,
                navigation: navigation
            };
        });
        englishBanners[ selectedCategories[i] ]["$mobile_top"] = englishBanners[ selectedCategories[i] ]["$mobile_top"].map((e, index) => {
            let imgPath = folderLocation.rootFolder+folderLocation[selectedCategories[i]]+'/'+e
            let navigation = insubuyPath.rootPath + insubuyPath[language][selectedCategories[i]] + '/?utm_source=immihelp&utm_medium=' + selectedCategories[i] + '&utm_campaign=MobileTop' + (index+1);
            return {
                imgPath: imgPath,
                navigation: navigation
            };
        });
        englishBanners[ selectedCategories[i] ]["$mobile_bottom"] = englishBanners[ selectedCategories[i] ]["$mobile_bottom"].map((e, index) => {
            let imgPath = folderLocation.rootFolder+folderLocation[selectedCategories[i]]+'/'+e
            let navigation = insubuyPath.rootPath + insubuyPath[language][selectedCategories[i]] + '/?utm_source=immihelp&utm_medium=' + selectedCategories[i] + '&utm_campaign=MobileBottom' + (index+1);
            return {
                imgPath: imgPath,
                navigation: navigation
            };
        });
        
        // Concat multiple arrays to one array
        verticalBanners = verticalBanners.concat(englishBanners[ selectedCategories[i] ]["$desktop_vertical"]);        
        verticalBanners.shuffleArray();
        horizontalBanners = horizontalBanners.concat(englishBanners[ selectedCategories[i] ]["$desktop_horizontal"]);
        horizontalBanners.shuffleArray();
        squareBanners = squareBanners.concat(englishBanners[ selectedCategories[i] ]["$desktop_square"]);
        squareBanners.shuffleArray();
        mobileTopBanners = mobileTopBanners.concat(englishBanners[ selectedCategories[i] ]["$mobile_top"]);
        mobileTopBanners.shuffleArray();
        mobileBottomBanners = mobileBottomBanners.concat(englishBanners[ selectedCategories[i] ]["$mobile_bottom"]);
        mobileBottomBanners.shuffleArray();
    }

    // Window object to see which ads are targeted.    
    window.revenueTargeted = verticalBanners;

    let choiceOfVerticalBanner = verticalBanners[generateRandomNumber(0, (verticalBanners.length - 1))];
    let $verticalBanner = getSelector('.vertical-banner');
    if($verticalBanner && choiceOfVerticalBanner) {
        getSelector('.vertical-banner a').href = choiceOfVerticalBanner.navigation;
        const vertCanvas = getSelector('.vertical-banner canvas');
        vertCanvas.width = 154;
        vertCanvas.height = 578;
        vertCanvas.style.backgroundImage = `url('${choiceOfVerticalBanner.imgPath}')`;
    }

    let choiceOfHorizontalBanner = horizontalBanners[generateRandomNumber(0, (horizontalBanners.length - 1))];
    let $horizontalBanner = getSelector('.horizontal-banner') 
    if($horizontalBanner && choiceOfHorizontalBanner) {
        getSelector('.horizontal-banner a').href = choiceOfHorizontalBanner.navigation;
        const canvasBanner = getSelector('.horizontal-banner canvas');
        canvasBanner.setAttribute('width', 970);
        canvasBanner.setAttribute('height', 90);
        canvasBanner.style.backgroundImage = `url('${choiceOfHorizontalBanner.imgPath}'`;
    }

    let choiceOfHorizontalBanner_footer = horizontalBanners[generateRandomNumber(0, (horizontalBanners.length - 1))];
    let $horizontalBanner_footer = getSelector('.horizontal-banner.footer-vilab') 
    if($horizontalBanner_footer && choiceOfHorizontalBanner_footer) {
        getSelector('.horizontal-banner.footer-vilab a').href = choiceOfHorizontalBanner_footer.navigation;
        const canvasBanner = getSelector('.horizontal-banner.footer-vilab canvas');
        canvasBanner.setAttribute('width', 970);
        canvasBanner.setAttribute('height', 90);
        canvasBanner.style.backgroundImage = `url('${choiceOfHorizontalBanner_footer.imgPath}'`;
    }

    let choiceOfSquareBanner = squareBanners[generateRandomNumber(0, (squareBanners.length - 1))];
    let $squareBanner = getSelector('.square-banner');
    if($squareBanner && choiceOfSquareBanner) {
        getSelector('.square-banner a').href = choiceOfSquareBanner.navigation;
        const canvasBanner = getSelector('.square-banner canvas');
        canvasBanner.setAttribute('width', 250);
        canvasBanner.setAttribute('height', 250);
        canvasBanner.style.backgroundImage = `url('${choiceOfSquareBanner.imgPath}'`;
    }

    let $stickySquareBanner = getSelector('.c-Sticky-adv .square-banner');
    if ($stickySquareBanner && choiceOfSquareBanner) {
        getSelector('.c-Sticky-adv .square-banner a').href = choiceOfSquareBanner.navigation;
        const canvasBanner = getSelector('.c-Sticky-adv .square-banner img');
        canvasBanner.setAttribute('width', 200);
        canvasBanner.setAttribute('height', 200);
        canvasBanner.style.backgroundImage = `url('${choiceOfSquareBanner.imgPath}')`;
    }

    // Overwrite square ads to widgets if one is selected
    let frameEnabled = false;
    if (categoryAds.indexOf('Visitors To USA (Widget)') > -1) {
        $squareBanner.innerHTML = '<iframe src="https://immihelp.brokersnexus.com/widget1/visitors-insurance/?aid=immihelp" width="100%" style="height: 450px; border: 0;"></iframe>';
        if ($stickySquareBanner) $stickySquareBanner.innerHTML = '<iframe src="https://immihelp.brokersnexus.com/widget1/visitors-insurance/?aid=immihelp" width="100%" style="height: 450px; border: 0;"></iframe>';
        frameEnabled = true;
    } else if (categoryAds.indexOf('Schengen Visa Insurance (Widget)') > -1) {
        $squareBanner.innerHTML = '<iframe src="https://immihelp.brokersnexus.com/widget1/schengen-visa-insurance/?aid=immihelp" width="100%" style="height: 350px; border: 0;"></iframe>';
        if ($stickySquareBanner) $stickySquareBanner.innerHTML = '<iframe src="https://immihelp.brokersnexus.com/widget1/schengen-visa-insurance/?aid=immihelp" width="100%" style="height: 350px; border: 0;"></iframe>';
        frameEnabled = true;
    }

    let choiceOfMobileTopBanner = mobileTopBanners[generateRandomNumber(0, (mobileTopBanners.length - 1))];
    let $mobileTopBanner = getSelector('.mobile-banner');
    if($mobileTopBanner) {
        getSelector('.mobile-banner a').href = choiceOfMobileTopBanner.navigation;
        const canvasBanner = getSelector('.mobile-banner canvas');
        canvasBanner.setAttribute('width', 437);
        canvasBanner.setAttribute('height', 73);
        canvasBanner.style.backgroundImage = `url('${choiceOfMobileTopBanner.imgPath}'`;
    }

    let choiceOfMobileBottomBanner = mobileBottomBanners[generateRandomNumber(0, (mobileBottomBanners.length - 1))];
    let $mobileBottomBanner = getSelector('.footer-vilab .square-banner');
    if($mobileBottomBanner) {
        const $mobileBottomBannerLink = getSelector('.footer-vilab .square-banner a');
        if ($mobileBottomBannerLink) {
            $mobileBottomBannerLink.href = choiceOfMobileBottomBanner.navigation;
            const canvasBanner = getSelector('.footer-vilab .square-banner canvas');
            canvasBanner.setAttribute('width', 336);
            canvasBanner.setAttribute('height', 280);
            canvasBanner.style.backgroundImage  = `url('${choiceOfMobileBottomBanner.imgPath}'`;
        }
    }

    let choiceOfRectangleBanner = mobileBottomBanners[generateRandomNumber(0, (mobileBottomBanners.length - 1))];
    let $rectangleBanner = getSelector('.rectangle-banner');
    if ($rectangleBanner) {
        getSelector('.rectangle-banner a').href = choiceOfRectangleBanner.navigation;
        const canvasBanner = getSelector('.rectangle-banner canvas');
        canvasBanner.setAttribute('width', 336);
        canvasBanner.setAttribute('height', 280);
        canvasBanner.style.backgroundImage = `url('${choiceOfRectangleBanner.imgPath}')`;
    }
    
    /**
     * To render banner ads in btw the page.
     */
    if(window.immiObj.homePage) {
        // if home page
        let englishBanners = new ListOfEnglishBanners();
    
        if(language == 'zh') {
            englishBanners = new ListOfChineseBanners();            
        }

        if(language == 'es') {
            englishBanners = new ListOfSpanishBanners();            
        }
        
        let allBanners = document.querySelectorAll('.horizontal-banner') || [];
        if (allBanners) allBanners = Array.from(allBanners);
        else allBanners = [];
        let choiceOfMobileBanner;
        let $mobileBanner;
        allBanners.forEach(e=>{
            const categoryType = e.getAttribute("data-category");
            const id = e.id;
            let mobileBanners = [];
            let $aLink = null;
            let $imgElm = null;
            if (id) {
                $aLink = document.querySelector('#' + id + ' a');
                $imgElm = document.querySelector('#' + id + ' canvas');
                var randomNum = generateRandomNumber(0, (englishBanners[categoryType]["$desktop_horizontal"].length - 1));
                var srcLink = insubuyPath.rootPath + insubuyPath[language][categoryType] + '/?utm_source=immihelp&utm_medium=' + categoryType + '&utm_campaign=HomePage' + randomNum;
                if ($aLink) $aLink.setAttribute('href', srcLink);
                if ($imgElm) {
                    $imgElm.setAttribute('width', 970);
                    $imgElm.setAttribute('height', 90);
                    $imgElm.style.backgroundImage = `url('${folderLocation.rootFolder + folderLocation[categoryType] + '/' + englishBanners[categoryType]["$desktop_horizontal"][randomNum]}')`;
                }
                
                if (categoryType == 'GroupTravel') {
                    mobileBanners = mobileBanners.concat(englishBanners[categoryType]["$mobile_bottom"]);
                    mobileBanners.shuffleArray();
                    choiceOfMobileBanner = mobileBanners[generateRandomNumber(0, (mobileBanners.length - 1))];
                    $mobileBanner = getSelector('.mobile-banner');
                    
                } else if (categoryType) {
                    mobileBanners = mobileBanners.concat(englishBanners[categoryType]["$mobile_top"]);
                    mobileBanners.shuffleArray();
                    choiceOfMobileBanner = mobileBanners[generateRandomNumber(0, (mobileBanners.length - 1))];
                    $mobileBanner = getSelector('.mobile-banner');
                }

                if ($mobileBanner) {
                    getSelector('#' + id + ' .mobile-banner a').href = srcLink;
                    const mbCanvas = getSelector('#' + id + ' .mobile-banner canvas');
                    if (mbCanvas) {
                        mbCanvas.setAttribute('width', 437);
                        mbCanvas.setAttribute('height', 73);
                        mbCanvas.style.backgroundImage = `url('${folderLocation.rootFolder + folderLocation[categoryType] + '/' + choiceOfMobileBanner}')`;
                    }
                }
            }
        })
    }
