<template>
    <div class="c-Experience-cat__reply borderline" :reply-id="node.replyId" :parent-id="parent">
        <div v-if="!isSponsors" class="c-Experience-cat-reply__content u-Justify" v-html="node.comment ? node.comment : node.reply"></div>
        <div v-else class="c-Experience-cat-reply__content u-Justify" v-html="node.formatedPositiveComment"></div>
        <div class="c-Experience-cat-reply__footer">
            <div class="c-Experience-cat-reply__date">
                <div v-if="node.claimer" class="c-Experience-cat-reply__verified">
                    <svg viewBox="0 0 9 9" v-if="node.claimer" class="c-Experience__verified-icon"><use xlink:href="/assets/build/svg/defs/svg/sprite.defs.svg#verified-employer"></use></svg>Verified Employer Representative
                </div>
                <span v-if="currentLangCode == 'zh'">
                    <a :href="node.userProfileLink">{{node.username ? node.username : node.userName}}</a> {{immiLang.replyTerms.replyBy}} {{node.repliedOn ? node.repliedOn : node.createdOnStr}}
                </span>
                <span v-if="currentLangCode == 'es'">
                    <a :href="node.userProfileLink">{{node.username ? node.username : node.userName}}</a> {{immiLang.replyTerms.replyBy}} {{node.repliedOn ? node.repliedOn : node.createdOnStr}}
                </span>
                <span v-else-if="!isSponsors">{{immiLang.replyTerms.replyBy}}: <a :href="node.userProfileLink">{{node.username ? node.username : node.userName}}</a> {{immiLang.replyTerms.on}} {{node.repliedOn ? node.repliedOn : node.createdOnStr}}</span>
                <span v-else>
                    {{immiLang.replyTerms.replyBy}}:                     
                    <a :href="'/user-profile/'+node.createdBy+'/employer-reviews/'">{{node.createdBy}}<svg viewBox="0 0 11 11" v-if="node.claimer" class="c-Comp-prof__title-icon-svg"><use xlink:href="/assets/build/svg/defs/svg/sprite.defs.svg#user-verified-badge-icon"></use></svg></a>
                     {{immiLang.replyTerms.on}} {{node.location}}
                </span>
            </div>
            <div class="c-Experience-cat-reply__count">
                <button type="button" class="c-Experience-cat-reply-count__item o-Btn-effect-tap" @click.prevent="rateThisPost(node.id, 'like')" :disabled="rate.disableLike || pauseSubmit">
                    <span>{{likeCount}}</span>                    
                    <svg viewBox="0 0 10 10" class="o-Icon__svg o-Icon__svg--thumbs-up" xml:space="preserve">
                        <use xlink:href="/assets/build/svg/defs/svg/sprite.defs.svg#thumb_up_icon" class="o-Icon__svg__inner"></use>
                    </svg>
                </button>
                <button type="button" class="c-Experience-cat-reply-count__item o-Btn-effect-tap" @click.prevent="rateThisPost(node.id, 'dislike')" :disabled="rate.disableDisLike || pauseSubmit">
                    <span>{{disLikeCount}}</span>                    
                    <svg viewBox="0 0 10 10" class="o-Icon__svg o-Icon__svg--thumbs-down" xml:space="preserve">
                        <use xlink:href="/assets/build/svg/defs/svg/sprite.defs.svg#thumb_down_icon" class="o-Icon__svg__inner"></use>
                    </svg>
                </button>
                <button v-if="hideReply != 'Disabled'" type="button" class="c-Experience-cat-reply-count__item" @click.prevent="triggerCloseOpenReply" :disabled="addReply">
                    {{immiLang.replyTerms.reply}}
                    <span>
                        <svg viewBox="0 0 10 10"  class="o-Icon__svg o-Icon__svg--reply">
                            <use xlink:href="/assets/build/svg/defs/svg/sprite.defs.svg#reply_icon"
                            class="Icon__svg__inner"></use>
                        </svg>
                    </span>
                </button>               
            </div>
        </div>
        <div>
            <add-reply :id="postId" :parent-id="node.id" :is-blog="isBlog" :is-sponsors="isSponsors" :show-reply="addReply" is-nested=true></add-reply>
        </div>

        <!-- Nested -->
        <div class="c-Experience-cat__reply c-Experience-cat__reply--nested">
            <nested v-for="reply in node.replies" :key="reply.replyId" :node="reply" :is-sponsors="isSponsors" :is-blog="isBlog" :parent="node.id" :post-id="postId"></nested>
        </div>
    </div>
</template>

<script>
import AddReply from './components.add-reply.vue';
import {
    isUserLoggedIn,
    RedirectsUrls,
    ApiUrls
} from '../helper-functions';
import {Spinner} from 'spin.js';

const spinOpts = {
    lines: 7 // The number of lines to draw
    , length: 17 // The length of each line
    , width: 6 // The line thickness
    , radius: 15 // The radius of the inner circle
    , scale: 0.2 // Scales overall size of the spinner
    , corners: 1 // Corner roundness (0..1)
    , color: '#0071bc' // #rgb or #rrggbb or array of colors
    , opacity: 0.75 // Opacity of the lines
    , rotate: 0 // The rotation offset
    , direction: 1 // 1: clockwise, -1: counterclockwise
    , speed: 1.4 // Rounds per second
    , trail: 60 // Afterglow percentage
    , fps: 20 // Frames per second when using setTimeout() as a fallback for CSS
    , zIndex: 2e9 // The z-index (defaults to 2000000000)
    , className: 'spinner' // The CSS class to assign to the spinner
    , top: '50%' // Top position relative to parent
    , left: '50%' // Left position relative to parent
    , shadow: false // Whether to render a shadow
    , hwaccel: true // Whether to use hardware acceleration
    , position: 'absolute' // Element positioning
};

export default {
    name: 'nested',
    components: {
        AddReply
    },
    props: {
        node: Object,
        parent: Number,
        postId: Number,
        isBlog: Boolean,
        isSponsors: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            addReply: false,
            rate: {
                isDisLikeLoading: false,
                isLikeLoading: false,
                disableLike: false,
                disableDisLike: false,
                likeCount: 0,
                disLikeCount: 0,
            },
            immiLang: window.immiLang || {},
            currentLangCode: window.immiObj?.currentLangCode || 'en',
            hideReply: window.immiObj?.moderation || 'Enabled',
            pauseSubmit: window.immiObj.pauseAction
        }
    },
    created () {
        this.$root.$on('closeNestedReplies', (id, closeAll) => {
            this.onCloseOtherReplies(id, closeAll);
        });
        this.$root.$on('closeThisReply', (id)=>{
            this.closeThisReply(id);
        });
        
        this.likeCount = this.node.likes;
        this.disLikeCount = this.node.disLikes;

        if(this.node.hasOwnProperty("childBlogCommentBeanList")) {
            if(!this.node.hasOwnProperty("replies ")) {
                this.node.replies = this.node.childBlogCommentBeanList;                
            }
        }

        if(!this.node.hasOwnProperty("reply") && this.node.hasOwnProperty("comment")) {
            this.node.reply = this.node.comment;
            this.node.repliedOn = this.node.createdOnStr;
            this.node.username = this.node.userName;
            this.node.replyId = this.node.id;
        } else if (this.node.hasOwnProperty("positiveComment") && this.isSponsors) {
            this.node.reply = this.node.formatedPositiveComment;
            this.node.username = this.node.createdBy;
            this.node.replyId = this.node.id;
            this.node.repliedOn = this.node.formattedCreatedOn;
        }
        
        if(this.isBlog && !this.node.replyId) this.node.replyId = this.node.id;

        if (this.isSponsors) {
            this.likeCount = this.node.likeCount;
            this.disLikeCount = this.node.disLikeCount;
            this.node.replies = this.node.replies;
        }

        if (window.immiObj.currentLangCode && window.immiObj.currentLangCode == 'zh' && this.node.hasOwnProperty("repliedOn")) {
            const enDate = this.node.repliedOn.substr(0,10); // MM/DD/YYYY
            const enDateSplit = enDate.split("/");
            let zhDate = `${enDateSplit[2]}-${enDateSplit[1]}-${enDateSplit[0]} ${this.node.repliedOn.substr(10)}`; //YYYY-MM-DD
            if(zhDate && zhDate.indexOf('AM') > -1)  {
                zhDate = zhDate.replace('AM','上午');
            } else if (zhDate && zhDate.indexOf('PM') > -1) {
                zhDate = zhDate.replace('PM', '下午');
            }
            this.node.repliedOn = zhDate;
        }

        if (window.immiObj.currentLangCode && window.immiObj.currentLangCode == 'es' && this.node.hasOwnProperty("repliedOn")) {
            const enDate = this.node.repliedOn.substr(0,10); // MM/DD/YYYY
            const enDateSplit = enDate.split("/");
            let esDate = `${enDateSplit[1]}/${enDateSplit[0]}/${enDateSplit[2]} ${this.node.repliedOn.substr(10)}`; //MM-DD-YYYY
            this.node.repliedOn = esDate;
        }
    },
    methods: {        
        triggerCloseOpenReply () {
            if(immiObj.ath.isLoggedIn) {
                this.addReply = true;
                this.$root.$emit('closeNestedReplies', this.node.id);
            } else {
                window.location.href = RedirectsUrls.login;
            }
        },
        onCloseOtherReplies (id, closeAll) {
            if(this.node.id != id || closeAll) {
                this.addReply = false;
            }            
            if(this.node.id == id) {
                this.closeMainReply();
            }
        },
        closeThisReply (id) {
            if(this.node.id == id) {
                this.addReply = false;
            }
        },
        closeMainReply () {
            this.$root.$emit('closeMainReply');
        },
        rateThisPost(id, type) {
            if(immiObj.ath.isLoggedIn) {
                let url = ""; 
                let spinTarget = "";
                switch (type) {
                    case 'dislike':
                        url = this.isBlog ? ApiUrls.blog.rateDisLike : this.isSponsors ? ApiUrls.sponsors.postDisLike : ApiUrls.experience.rateDisLike;
                        this.rate.isDisLikeLoading = true;
                        spinTarget = document.getElementById('dislike_' + id);
                        this.disLikeCount += 1;
                        this.rate.disableLike = true;
                        break;

                    default:
                        // like
                        url = this.isBlog ? ApiUrls.blog.rateLike : this.isSponsors ? ApiUrls.sponsors.postLike : ApiUrls.experience.rateLike;
                        this.rate.isLikeLoading = true;
                        spinTarget = document.getElementById('like_' + id);
                        this.likeCount += 1;                        
                        this.rate.disableDisLike = true;
                }
                if (!this.isSponsors) url += id + '/';
                const data = this.isSponsors ? {id} : null;
                var spinnerlike = new Spinner(spinOpts).spin(spinTarget);

                this.$http.post(url, data).then(response => {
                    const responseBody = response.body;
                    this.rate.isDisLikeLoading = false;
                    this.rate.isLikeLoading = false;
                    if (responseBody.message === 'success') {
                        // Rating already incremented.
                    } else {
                        let errorMessage = (responseBody.error && Array.isArray(responseBody.error)) ? responseBody.error[0] : 'Something went wrong, please try again.';
                        if(window.immiObj.currentLangCode && window.immiObj.currentLangCode == 'zh' && errorMessage == 'It is already rated.') {
                            errorMessage = "已被评为。";
                        }
                        if(window.immiObj.currentLangCode && window.immiObj.currentLangCode == 'es' && errorMessage == 'It is already rated.') {
                            errorMessage = "Ya está clasificado.";
                        }
                        this.$root.$emit('openSnackbar', errorMessage);
                    }
                }, error => {
                    this.rate.isDisLikeLoading = false;
                    this.rate.isLikeLoading = false;                    
                });            
            } else {
                window.location.href = RedirectsUrls.login;
            }
        }
    }
}
</script>